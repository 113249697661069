export const STTMODULE = "STT"
export const TTSMODULE = "TTS"
export const DISTRIBUSION_SOURCE="Distribution Source-wise"

// status
export const STATUS_APPROVED = 'Approved';
export const STATUS_REJECTED = 'Rejected';
export const STATUS_CANCELLED = 'Cancelled';
export const STATUS_REJECT = 'Reject';
export const STATUS_DECLINED = 'Claim Declined';
export const STATUS_CLAIM_APPROVED = 'Claim Approved'
export const STATUS_REASSIGNED = 'Reassigned';
export const STATUS_ANNOTATED = 'Annotated';
export const STATUS_ANNOTATING = 'Annotating';
export const STATUS_VALIDATED = 'Validated';
export const STATUS_VALIDATING = 'Validating';
export const STATUS_TOOK_A_BREAK = 'Took a Break';
export const STATUS_CLAIMED = 'Claimed';
export const STATUS_ACTIVE = 'Active';
export const STATUS_BLOCKED = 'Blocked';
export const STATUS_CHECKED = 'Checked';
export const STATUS_UPOLOADED = 'Uploaded';



export const AUDIO_FILE_UPLOADED = 'Approved';
export const AUDIO_FILE_FAILED = 'Upload Failed';

