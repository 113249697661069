import currency from "./currency.svg";
import admin from "./admin.svg";
import manager from "./manager.svg";
import teamLeader from "./teamLeader.svg";
import collector from "./collector.svg";
import audioChecker from "./audioChecker.svg";
import annotator from "./annotator.svg";
import validator from "./validator.svg";
import speakerMale from "./speakerMale.svg";
import speakerFemale from "./speakerFemale.svg";
import hamburger from "./hamburger.svg";
import post_add from "./post_add.svg";
import activity from "./activity.svg";
import dashboard from "./dashboard.svg";
import group from "./group.svg";
import songDashboard from "./songDashboard.svg";
import target from "./target.svg";
import user2 from "./user2.svg";
import settings from "./settings.svg";
import whiteDropArrow from "./whiteDropArrow.svg";
import blackDropArrow from "./blackDropArrow.svg";
import arrow_back from "./arrow_back.svg";
import military_tech from "./military_tech.svg";
import mail from "./mail.svg";
import call from "./call.svg";
import home from "./home.svg";
import calender from "./calender.svg";
import BorderAll from "./border_all.png";
import BrokenImg from "./broken_image.png";
import PictureAsPdf from "./picture_as_pdf.png";
import Print from "./print.png";
import CloseIconButton from "./close.svg";
import sourceDistributionMenu from "./MenuVector.svg";
import blueDropArrow from "./arrow_drop_down.svg";
import lightDropDown from "./arrow_drop_down.png";
import CorrectIcon from "./check.svg";
import schedule from "./schedule.svg";
import left_indicator from "./left_indicator.svg";
import right_indicator from "./right_indicator.svg";
import Barendri from "./Barendri.svg";
import MiddleEastBangla from "./MiddleEastBangla.svg";
import NorthEastBangla from "./NorthEastBangla.svg";
import Rajbongshi from "./Rajbongshi.svg";
import SouthBangla from "./SouthBangla.svg";
import SouthEastBangla from "./SouthEastBangla.svg";
import Kamrupi from "./Kamrupi.svg";
import left_arrow_black from "./left_arrow_black.svg";
import closeBlue from "./closeBlue.svg";
import arrow_drop_down_blue_gray from "./arrow_drop_down_blue_gray.svg";
import search from "./search.svg";
import upload from "./upload.svg";
import filter_list from "./filter_list.svg";
import dark_right_arrow from "./dark_right_arrow.svg";
import dark_up_arrow from "./dark_up_arrow.svg";
import open_in_new from "./open_in_new.svg";
import moreIcon from "./more_horiz.svg";
import fileDownload from "./file_download.svg";
import contentCopy from "./content_copy.svg";
import lyrics from "./lyrics.png";
import ArrowRight from "./arrow_right.png";
import ArrowDropUp from "./arrow_drop_up.svg";
import ChevronRight from "./chevron_right.svg";
import Link from "./link.svg";
import Link_Black from "./link_black.svg";
import AddLink from "./add_link.svg";
import AddLink_Black from "./add_link_black.svg";
import Backup from "./backup.svg";
import Add from "./add.svg";
import ArrowRightBack from "./arrow_right_alt.svg";
import MusicIcon from "./music 1.svg";
import Title from "./title.svg";
import DisableCursor from "./disableCursor.svg";
import arrow_drop_down_white from "./arrow_drop_down_white.svg";
import dashboardNav from "./dashboardNav.svg";
import scriptNav from "./scriptNav.svg";
import assignNav from "./assignNav.svg";
import audioNav from "./audioNav.svg";
import userNav from "./userNav.svg";
import organizeNav from "./organizeNav.svg";
import DottedDownArrow from "./dotted-down-arrow.svg";
import Gavel from "./gavel.svg";
import Shadow_bg from "./shadow_bg.png";
import Avatar from "./Avatar.svg";
import Up from "./up.svg";
import createSkeleton from "./createSkeleton.svg";
import collectSkeleton from "./collectSkeleton.svg";
import double_up_arrow from "./dubble_up_arrow.svg";
import Script from "./script.svg";
import BlueMusicIcon from "./blueMusicArrow.svg";
import BlueRightArrow from "./blueRightArrow.svg";
import Unfold_More from './unfold_more.svg'
import calenderIcon from './calender_icon.svg';
import info from './info.svg';
import check_green from './check_green.svg';
import warning from './warning.svg';
import calender_white from './calender_white.svg';
import DoubleDarkICon from './double_dark_arrow_up.svg';
import Vector from './Vector.svg';
import Vector2 from './Vector2.svg';
import DoubleArroDownDark from './double_dark_arrow_down.svg';
import AddBlue from './add_blue.png';
import cancel from './cancel.svg';
import ArrowRightAltWhite from './arrow_right_alt_white.svg';
import SpeakerFemale from './speaker_female.svg';
import bold_light_right_arrow from './bold_light_right_arrow.svg';
import bold_light_left_arrow from './bold_light_left_arrow.svg';
import Military from './military_tech.png';
import Home from './home.png';
import Power from './power_rounded.png';
import deleteIcon from './delete_icon.png';
import Face from './face.png';
import HomeLight from './home_light.svg';
import Pdf from './picture_as_pdf.svg';
import Write from './write.svg';
import Loading from './loading.svg';
import North from "./north.svg";
import NorthNeviBlue from "./north_nevi_blue.svg";
import South from "./south.svg";
import SouthNeviBlue from "./south_nevi_blue.svg";
import edit_blue from "./edit.svg";
import delete_red from "./delete.svg";
import MusicBlue from './music_blue.svg';
import up_arrow_black from "./up_arrow_black.svg";
import arrow_drop_down_blue_gray_45 from "./arrow_drop_down_blue_gray_45.svg";
import PhotoGallery from "./photo-gallery 1.svg";
import Blank from "./blank_image.svg";
import UploadMagenta from "./upload_magenta.svg";
import AccountCircle from "./account_circle.svg";
import EditGray from "./edit_gray.svg";
import FluentMagic from "./fluent_magic.svg";
import Play from "./play.svg";
import Pause from "./pause.svg";
import IconsWrapper from "./Icon_Wrapper.png";
import East from "./east.svg";
import Conversion from "./conversion_path.svg";
import openInNewGray from "./open_in_new_gray.svg"
import File from "./File.svg";
import check_magenta from "./check_magenta.svg";
import check_blue from "./check_blue.svg";
import check_red from "./check_red.svg";
import like_emoji from "./like_emoji.svg";
import AssignmentReturn from "./assignment_return.svg";
import union from "./union.svg";
import arrow_right_rounded_blue from "./arrow_right_rounded_blue.svg";
import settings_purple from "./settings_purple.svg";
import Laptop from './Laptop.svg';
import Mobile from './Mobile.svg';
import AudioRecorder from './AudioRecorder.svg';
import Vector3 from './Vector_3.png';
import UserSnippet from './User_Snippet_Skeleton.svg'
import metaDataImage from './scriptMetadataImage.png'
import LatestCalBlue from './icon_wrapper_latest_blue.svg'
import LatestCalGray from './icon_wrapper_latest_gray.svg'
import OldestCalBlue from './icon_wrapper_oldest_blue.svg'
import OldestCalGray from './icon_wrapper_oldest_gray.svg'
import Rectangle1 from './rectancle_1.svg';
import Rectangle2 from './rectangle_2.svg';
import UserGray from './user_gray.svg';
import GoBack from './go_back.svg';
import statistics from './statistics.svg';
import union_box from './union_box.svg';
import LoadingBlue from './loading_blue.svg';
import LoadingGray from './loading_ct_blue.svg';
import LoadingMagenta from './loading_magenta.svg';
import LoadingWhite from './loading_white.svg';
import Leading from './leading_icon.svg';

const Icons = {
  Leading,
  statistics,
  OldestCalGray,
  OldestCalBlue,
  LatestCalGray,
  LatestCalBlue,
  currency,
  admin,
  hamburger,
  post_add,
  activity,
  dashboard,
  group,
  songDashboard,
  target,
  user2,
  settings,
  settings_purple,
  whiteDropArrow,
  blackDropArrow,
  arrow_back,
  manager,
  teamLeader,
  collector,
  audioChecker,
  annotator,
  validator,
  speakerMale,
  speakerFemale,
  military_tech,
  mail,
  call,
  home,
  calender,
  BorderAll,
  BrokenImg,
  PictureAsPdf,
  Print,
  CloseIconButton,
  sourceDistributionMenu,
  blueDropArrow,
  CorrectIcon,
  schedule,
  left_indicator,
  right_indicator,
  Barendri,
  MiddleEastBangla,
  NorthEastBangla,
  Rajbongshi,
  SouthBangla,
  SouthEastBangla,
  Kamrupi,
  left_arrow_black,
  closeBlue,
  arrow_drop_down_blue_gray,
  search,
  upload,
  filter_list,
  dark_right_arrow,
  dark_up_arrow,
  open_in_new,
  moreIcon,
  fileDownload,
  contentCopy,
  lyrics,
  ArrowRight,
  ArrowDropUp,
  ChevronRight,
  Link,
  Link_Black,
  AddLink,
  AddLink_Black,
  Backup,
  Add,
  ArrowRightBack,
  MusicIcon,
  Title,
  DisableCursor,
  Avatar,
  arrow_drop_down_white,
  dashboardNav,
  scriptNav,
  assignNav,
  audioNav,
  userNav,
  organizeNav,
  DottedDownArrow,
  Gavel,
  Shadow_bg,
  Up,
  createSkeleton,
  collectSkeleton,
  Unfold_More,
  calenderIcon,
  double_up_arrow,
  info,
  check_red,
  check_green,
  check_blue,
  check_magenta,
  warning,
  calender_white,
  Script,
  BlueMusicIcon,
  BlueRightArrow,
  DoubleDarkICon,
  Vector,
  Vector2,
  DoubleArroDownDark,
  AddBlue,
  cancel,
  ArrowRightAltWhite,
  SpeakerFemale,
  bold_light_right_arrow,
  bold_light_left_arrow,
  lightDropDown,
  Home,
  Power,
  deleteIcon,
  Face,
  Military,
  HomeLight,
  Pdf,
  Write,
  Loading,
  North,
  NorthNeviBlue,
  South,
  SouthNeviBlue,
  edit_blue,
  delete_red,
  MusicBlue,
  up_arrow_black,
  arrow_drop_down_blue_gray_45,
  PhotoGallery,
  Blank,
  UploadMagenta,
  AccountCircle,
  EditGray,
  FluentMagic,
  Play,
  Pause,
  IconsWrapper,
  East,
  Conversion,
  openInNewGray,
  File,
  like_emoji,
  AssignmentReturn,
  union,
  arrow_right_rounded_blue,
  Laptop,
  Mobile,
  AudioRecorder,
  Vector3,
  UserSnippet,
  metaDataImage,
  Rectangle1,
  Rectangle2,
  UserGray,
  GoBack,
  union_box,
  LoadingBlue,
  LoadingGray,
  LoadingMagenta,
  LoadingWhite
};

export default Icons;
