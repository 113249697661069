import BillingProvider from "./BillingProvider";
import RoleProvider from "./RoleProvider";
import CommonProvider from "./CommonProvider";
import DashboardProvider from "./DashboardProvider";
import ScriptProvider from "./ScriptProvider";
import UserManagementProvider from "./UserManagementProvider";
import AssignProvider from "./AssignProvider";
import AudioManagementProvider from "./AudioManagementProvider";
import OrganizerProvider from "./OrganizerProvider";

const Context = {
  BillingProvider,
  RoleProvider,
  CommonProvider,
  DashboardProvider,
  ScriptProvider,
  UserManagementProvider,
  AssignProvider,
  AudioManagementProvider,
  OrganizerProvider
};

export default Context;
