import { IconButton } from "./IconButton";
import { IconWithTextButton } from "./IconWithTextButton";
import { LabelButton } from "./LabelButton";
import ToggleRounded from "./ToggleRounded";
import BgHoverBtn from "./BgHoverBtn";
import IconWithTitle from "./IconWithTitle";
import Filter from "./Filter";
import { TabButton } from "./TabButton";
import Dropdown from "./Dropdown";
import Date from "./Date";
import CalenderBtn from "./CalenderBtn";
import DropdownWeekBtn from "./DropdownWeekBtn";
import { DateDropdown } from "./DateDropdown";
import ChoiceButton from './ChoiceButton';
import {LoadingButton} from './LoadingButton';


const Buttons = {
  ToggleRounded,
  LabelButton,
  IconButton,
  BgHoverBtn,
  IconWithTitle,
  IconWithTextButton,
  Filter,
  TabButton,
  Dropdown,
  Date,
  CalenderBtn,
  DropdownWeekBtn,
  DateDropdown,
  ChoiceButton,
  LoadingButton
};

export default Buttons;
