import paymentHistoryBody from "./paymentHistoryBody.svg";
import paymentHistoryHeader from "./paymentHistoryHeader.svg";
import totalAmountDisbursed from "./totalAmountDisbursed.svg";
import timeWiseDisbursement from "./timeWiseDisbursement.svg";
import totalDataAndOverTheTime from './totalDataSkeleton.png';
import overTheTimeSkeleton from './overTheTimeSkeleton.png';
import AssignTable from './AssignTable.png';
import sidebarSkeleton from './sidebarSkeleton.svg'
import navbarSkeleton from './navbarSkeleton.svg'


export const LoadingSkeleton = {
  sidebarSkeleton,
  navbarSkeleton,
  paymentHistoryBody,
  paymentHistoryHeader,
  totalAmountDisbursed,
  timeWiseDisbursement,
  totalDataAndOverTheTime,
  overTheTimeSkeleton,
  AssignTable
};
