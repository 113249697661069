import { STATUS_ACTIVE, STATUS_ANNOTATED, STATUS_ANNOTATING, STATUS_APPROVED, STATUS_BLOCKED, STATUS_CANCELLED, STATUS_CHECKED, STATUS_CLAIMED, STATUS_CLAIM_APPROVED, STATUS_DECLINED, STATUS_REASSIGNED, STATUS_REJECTED, STATUS_TOOK_A_BREAK, STATUS_UPOLOADED, STATUS_VALIDATED, STATUS_VALIDATING, STTMODULE, TTSMODULE } from "../../helpers/ConditionVariable"
import { targetFilterDT } from "../../types/assignTypes"

export const collectedAudio = {
    total_data_size: 1,
    data: [
        {
            "id": "1gaytertgq96",
            "speech": {
                "id": '0',
                "title": "Brahms,Two Pianos",
                "duration": "5:00",
                "url":
                    "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
            },
            "script": {
                "module": "STT",
                "distributionSource": "Lecture",
                "isAge": false,
                "domain": "Applied science",
                "subdomain": "Technology",

                "sourceType": "",
                "sourceUrl": "",
                "sourceFile": "",
                "sourceFileName": "",
                "sourceFileImage": "",
                "title": "",
                "description": "মহেশপুর থানার ওসি সাইফুল ইসলাম বলেন, খবর পেয়ে পুলিশ ঘটনাস্থলে পৌঁছে মরদেহটি উদ্ধার করে থানায় নিয়ে আসে। পঁয়ত্রিশ - চল্লিশ বছর বয়সী এই নারীর পরনে সালোয়ার কামিজ রয়েছে।",
                "date": "2023-02-16T12:45:58.536350",
                "frequency": 0,
                "id": "a9750dad-0e9b-4d23-9585-c7ff249616e9_20"
            },
            "speaker": {
                "locality": "North-East Bangla",
                "speakers": [
                    {
                        "id": "bilkis@gmail.com",
                        "name": 'Bilkis banu',
                        "role": 'speaker',
                        "gender": 'female',
                        "childhoodArea": "Nator",
                        "age": "27",
                        "profession": "teacher",
                        "economicSituation": "poor",
                        "education": "secondary",
                        "smokingHabit": "Prefer not say",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is"
                    },
                    {
                        "id": "maksud@gmail.com",
                        "name": 'maksud alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "barishal",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                    {
                        "id": "rakib@gmail.com",
                        "name": 'rakib alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "rangpur",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                ],

            },
            "remark": [
                {
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Claim Approved"
                },
                {
                    "id": "385",
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Cancelled"
                },
            ],
            "others": {
                "device": 'Redmi-Note 7 pro',
                "journey": {
                    "role": [
                        {
                            "id": "1",
                            "role": 'Admin',
                            "name": 'Md. Jalal Uddin',
                            "date": "01 Jan’ 22"
                        },
                        {
                            "id": "2",
                            "role": 'Manager',
                            "name": 'Md. Eman Hassan',
                            "date": "03 Jan’ 22"
                        },
                        {
                            "id": "3",
                            "role": 'Team Leader',
                            "name": 'Jahida Ferdous Mim',
                            "date": "05 Jan’ 22"
                        },
                        {
                            "id": "4",
                            "role": 'Collector',
                            "name": 'Muhammad Miraz Mahmud',
                            "date": "05 Jan’ 22"
                        },
                    ],
                    "speakers":
                    {
                        "locality": 'Middle-East Bangal',
                        "roleTitle": 'speaker',
                        "role": [
                            {
                                "id": "22",
                                "name": 'Raihan Mozumdar',
                                "gender": "male"
                            },
                            {
                                "id": "223",
                                "name": 'Bilkis Khatun',
                                "gender": "female"
                            }
                        ]
                    },
                    "audioChecker": {
                        "id": "55",
                        "locality": "North-East  Bangla",
                        "name": "Zakir Hossain",
                        "role": 'Audio Checker',
                        "pickedDate": "23 Jan’23; 3:30 PM"
                    }
                }
            },
        },
        {
            "id": "1astgayterrga",
            "speech": {
                "id": '0',
                "title": "Brahms,Two Pianos",
                "duration": "5:00",
                "url":
                    "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
            },
            "script": {
                "module": "STT",
                "distributionSource": "Lecture",
                "isAge": false,
                "domain": "Applied science",
                "subdomain": "Technology",

                "sourceType": "",
                "sourceUrl": "",
                "sourceFile": "",
                "sourceFileName": "",
                "sourceFileImage": "",
                "title": "",
                "description": "মহেশপুর থানার ওসি সাইফুল ইসলাম বলেন, খবর পেয়ে পুলিশ ঘটনাস্থলে পৌঁছে মরদেহটি উদ্ধার করে থানায় নিয়ে আসে। পঁয়ত্রিশ - চল্লিশ বছর বয়সী এই নারীর পরনে সালোয়ার কামিজ রয়েছে।",
                "date": "2023-02-16T12:45:58.536350",
                "frequency": 0,
                "id": "a9750dad-0e9b-4d23-9585-c7ff249616e9_20"
            },
            "speaker": {
                "locality": "North-East Bangla",
                "speakers": [
                    {
                        "id": "bilkis@gmail.com",
                        "name": 'Bilkis banu',
                        "role": 'speaker',
                        "gender": 'female',
                        "childhoodArea": "Nator",
                        "age": "27",
                        "profession": "teacher",
                        "economicSituation": "poor",
                        "education": "secondary",
                        "smokingHabit": "Prefer not say",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is"
                    },
                    // {
                    //     "id": "maksud@gmail.com",
                    //     "name": 'maksud alam',
                    //     "role": 'speaker',
                    //     "gender": 'male',
                    //     "childhoodArea": "barishal",
                    //     "age": "55",
                    //     "profession": "Doctor",
                    //     "economicSituation": "rich",
                    //     "education": "higher secondary",
                    //     "smokingHabit": "yes",
                    //     "hearingDisability": "no",
                    //     "shutter": "yes",
                    //     "recordingArea": "Inside Room",
                    //     "recordingDistance": "Close",
                    //     "note": "Dark Ux is ---"
                    // },
                    {
                        "id": "rakib@gmail.com",
                        "name": 'rakib alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "rangpur",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                ],

            },
            "remark": [
                {
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Claim Approved"
                },
                {
                    "id": "385",
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Cancelled"
                },
            ],
            "others": {
                "device": 'Redmi-Note 7 pro',
                "journey": {
                    "role": [
                        {
                            "id": "1",
                            "role": 'Admin',
                            "name": 'Md. Jalal Uddin',
                            "date": "01 Jan’ 22"
                        },
                        {
                            "id": "2",
                            "role": 'Manager',
                            "name": 'Md. Eman Hassan',
                            "date": "03 Jan’ 22"
                        },
                        {
                            "id": "3",
                            "role": 'Team Leader',
                            "name": 'Jahida Ferdous Mim',
                            "date": "05 Jan’ 22"
                        },
                        {
                            "id": "4",
                            "role": 'Collector',
                            "name": 'Muhammad Miraz Mahmud',
                            "date": "05 Jan’ 22"
                        },
                    ],
                    "speakers":
                    {
                        "locality": 'Middle-East Bangal',
                        "roleTitle": 'speaker',
                        "role": [
                            {
                                "id": "22",
                                "name": 'Raihan Mozumdar',
                                "gender": "male"
                            },
                            {
                                "id": "223",
                                "name": 'Bilkis Khatun',
                                "gender": "female"
                            }
                        ]
                    },
                    "audioChecker": {
                        "id": "55",
                        "locality": "North-East  Bangla",
                        "name": "Zakir Hossain",
                        "role": 'Audio Checker',
                        "pickedDate": "23 Jan’23; 3:30 PM"
                    }
                }
            },
        }
    ]
}

export const checkingStatusData = {
    total_data_size: 1,
    data: [
        {
            "id": "1yb2bse5ywetyb",
            "speech": {
                "id": '0',
                "title": "Brahms,Two Pianos",
                "duration": "5:00",
                "url":
                    "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
            },
            "deadline": "01/01/2022",
            "script": {
                "module": "STT",
                "distributionSource": "Lecture",
                "isAge": false,
                "domain": "Applied science",
                "subdomain": "Technology",
                "sourceType": "",
                "sourceUrl": "",
                "sourceFile": "",
                "sourceFileName": "",
                // "sourceFileImage": "",
                "title": "",
                "description": "মহেশপুর থানার ওসি সাইফুল ইসলাম বলেন, খবর পেয়ে পুলিশ ঘটনাস্থলে পৌঁছে মরদেহটি উদ্ধার করে থানায় নিয়ে আসে। পঁয়ত্রিশ - চল্লিশ বছর বয়সী এই নারীর পরনে সালোয়ার কামিজ রয়েছে।",
                "date": "2023-02-16T12:45:58.536350",
                // "frequency": 0,
                "id": "a9750dad-0e9b-4d23-9585-c7ff249616e9_20"
            },
            "speaker": {
                "locality": "North-East Bangla",
                "speakers": [
                    {
                        "id": "bilkis@gmail.com",
                        "name": 'Bilkis banu',
                        "role": 'speaker',
                        "gender": 'female',
                        "childhoodArea": "Nator",
                        "age": "27",
                        "profession": "teacher",
                        "economicSituation": "poor",
                        "education": "secondary",
                        "smokingHabit": "Prefer not say",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is"
                    },
                    {
                        "id": "maksud@gmail.com",
                        "name": 'maksud alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "barishal",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                    {
                        "id": "rakib@gmail.com",
                        "name": 'rakib alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "rangpur",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                ],

            },
            "audioChecker": {
                "status": "Checking",
                "name": "Peter Bailish",
                "role": "annotator",
                "locality": "Middle-East Bangla",
                "time": "01 Feb ; 3:30 PM"
            },
            "deadLine": "01/02/2022",
            "remark": [
                {
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Claim Approved"
                },
                {
                    "id": "385",
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Cancelled"
                },
            ],
            "others": {
                "device": 'Redmi-Note 7 pro',
                "journey": {
                    "role": [
                        {
                            "id": "1",
                            "role": 'Admin',
                            "name": 'Md. Jalal Uddin',
                            "date": "01 Jan’ 22"
                        },
                        {
                            "id": "2",
                            "role": 'Manager',
                            "name": 'Md. Eman Hassan',
                            "date": "03 Jan’ 22"
                        },
                        {
                            "id": "3",
                            "role": 'Team Leader',
                            "name": 'Jahida Ferdous Mim',
                            "date": "05 Jan’ 22"
                        },
                        {
                            "id": "4",
                            "role": 'Collector',
                            "name": 'Muhammad Miraz Mahmud',
                            "date": "05 Jan’ 22"
                        },
                    ],
                    "speakers":
                    {
                        "locality": 'Middle-East Bangal',
                        "roleTitle": 'speaker',
                        "role": [
                            {
                                "id": "22",
                                "name": 'Raihan Mozumdar',
                                "gender": "male"
                            },
                            {
                                "id": "223",
                                "name": 'Bilkis Khatun',
                                "gender": "female"
                            }
                        ]
                    },
                    "audioChecker": {
                        "id": "55",
                        "locality": "North-East  Bangla",
                        "name": "Zakir Hossain",
                        "role": 'Audio Checker',
                        "pickedDate": "23 Jan’23; 3:30 PM"
                    }
                }
            },

        },
        {
            "id": "1yb2bywetyb",
            "speech": {
                "id": '0',
                "title": "Brahms,Two Pianos",
                "duration": "5:00",
                "url":
                    "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
            },
            "deadline": "01/01/2022",
            "script": {
                "module": "STT",
                "distributionSource": "Lecture",
                "isAge": false,
                "domain": "Applied science",
                "subdomain": "Technology",
                "sourceType": "",
                "sourceUrl": "",
                "sourceFile": "",
                "sourceFileName": "",
                // "sourceFileImage": "",
                "title": "",
                "description": "মহেশপুর থানার ওসি সাইফুল ইসলাম বলেন, খবর পেয়ে পুলিশ ঘটনাস্থলে পৌঁছে মরদেহটি উদ্ধার করে থানায় নিয়ে আসে। পঁয়ত্রিশ - চল্লিশ বছর বয়সী এই নারীর পরনে সালোয়ার কামিজ রয়েছে।",
                "date": "2023-02-16T12:45:58.536350",
                // "frequency": 0,
                "id": "a9750dad-0e9b-4d23-9585-c7ff249616e9_20"
            },
            "speaker": {
                "locality": "North-East Bangla",
                "speakers": [
                    {
                        "id": "bilkis@gmail.com",
                        "name": 'Bilkis banu',
                        "role": 'speaker',
                        "gender": 'female',
                        "childhoodArea": "Nator",
                        "age": "27",
                        "profession": "teacher",
                        "economicSituation": "poor",
                        "education": "secondary",
                        "smokingHabit": "Prefer not say",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is"
                    },
                    {
                        "id": "maksud@gmail.com",
                        "name": 'maksud alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "barishal",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                    {
                        "id": "rakib@gmail.com",
                        "name": 'rakib alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "rangpur",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                ],

            },
            "audioChecker": {
                "status": "Checking",
                "name": "Peter Bailish",
                "role": "annotator",
                "locality": "Middle-East Bangla",
                "time": "01 Feb ; 3:30 PM"
            },
            "deadLine": "01/02/2022",
            "remark": [
                {
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Claim Approved"
                },
                {
                    "id": "385",
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Cancelled"
                },
            ],
            "others": {
                "device": 'Redmi-Note 7 pro',
                "journey": {
                    "role": [
                        {
                            "id": "1",
                            "role": 'Admin',
                            "name": 'Md. Jalal Uddin',
                            "date": "01 Jan’ 22"
                        },
                        {
                            "id": "2",
                            "role": 'Manager',
                            "name": 'Md. Eman Hassan',
                            "date": "03 Jan’ 22"
                        },
                        {
                            "id": "3",
                            "role": 'Team Leader',
                            "name": 'Jahida Ferdous Mim',
                            "date": "05 Jan’ 22"
                        },
                        {
                            "id": "4",
                            "role": 'Collector',
                            "name": 'Muhammad Miraz Mahmud',
                            "date": "05 Jan’ 22"
                        },
                    ],
                    "speakers":
                    {
                        "locality": 'Middle-East Bangal',
                        "roleTitle": 'speaker',
                        "role": [
                            {
                                "id": "22",
                                "name": 'Raihan Mozumdar',
                                "gender": "male"
                            },
                            {
                                "id": "223",
                                "name": 'Bilkis Khatun',
                                "gender": "female"
                            }
                        ]
                    },
                    "audioChecker": {
                        "id": "55",
                        "locality": "North-East  Bangla",
                        "name": "Zakir Hossain",
                        "role": 'Audio Checker',
                        "pickedDate": "23 Jan’23; 3:30 PM"
                    }
                }
            },

        },
    ]
}

export const allCheckedAudiosData = {
    total_data_size: 500,
    data: [
        {
            "id": "1vbuyb4yw45b",
            "deadline": "01/01/2022",
            "speech": {
                "id": '0',
                "title": "Brahms,Two Pianos",
                "duration": "5:00",
                "url":
                    "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
            },
            "speaker": {
                "locality": "North-East Bangla",
                "speakers": [
                    {
                        "id": "bilkis@gmail.com",
                        "name": 'Bilkis banu',
                        "role": 'speaker',
                        "gender": 'female',
                        "childhoodArea": "Nator",
                        "age": "27",
                        "profession": "teacher",
                        "economicSituation": "poor",
                        "education": "secondary",
                        "smokingHabit": "Prefer not say",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is"
                    },
                    {
                        "id": "maksud@gmail.com",
                        "name": 'maksud alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "barishal",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                    {
                        "id": "rakib@gmail.com",
                        "name": 'rakib alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "rangpur",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                ],

            },
            "audioChecker": {
                "status": "Checking",
                "name": "Peter Bailish",
                "role": "annotator",
                "locality": "Middle-East Bangla",
                "time": "01 Feb ; 3:30 PM"
            },
            "status": "Claim Declined",
            "script": {
                "module": "STT",
                "distributionSource": "Lecture",
                "isAge": false,
                "domain": "Applied science",
                "subdomain": "Technology",

                "sourceType": "",
                "sourceUrl": "",
                "sourceFile": "https://fastly.picsum.photos/id/923/200/300.jpg?hmac=eiYSYaG7v46VlrE38Amrg33bd2FzVjaCsQrLMdekyAU",
                "sourceFileName": "",
                // "sourceFileImage": "https://fastly.picsum.photos/id/923/200/300.jpg?hmac=eiYSYaG7v46VlrE38Amrg33bd2FzVjaCsQrLMdekyAU",
                "title": "ভুল বাক্য ",
                "description": "মহেশপুর থানার ওসি সাইফুল ইসলাম বলেন, খবর পেয়ে পুলিশ ঘটনাস্থলে পৌঁছে মরদেহটি উদ্ধার করে থানায় নিয়ে আসে। পঁয়ত্রিশ - চল্লিশ বছর বয়সী এই নারীর পরনে সালোয়ার কামিজ রয়েছে।",
                "date": "2023-02-16T12:45:58.536350",
                "frequency": 0,
                "id": "a9750dad-0e9b-4d23-9585-c7ff249616e9_20"
            },
            "deadLine": "01/02/2022",
            "submissionDate": "01/02/2022",
            "remark": [
                {
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Claim Approved"
                },
                {
                    "id": "385",
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Cancelled"
                },
            ],
            "others": {
                "device": 'Redmi-Note 7 pro',
                "journey": {
                    "role": [
                        {
                            "id": "1",
                            "role": 'Admin',
                            "name": 'Md. Jalal Uddin',
                            "date": "01 Jan’ 22"
                        },
                        {
                            "id": "2",
                            "role": 'Manager',
                            "name": 'Md. Eman Hassan',
                            "date": "03 Jan’ 22"
                        },
                        {
                            "id": "3",
                            "role": 'Team Leader',
                            "name": 'Jahida Ferdous Mim',
                            "date": "05 Jan’ 22"
                        },
                        {
                            "id": "4",
                            "role": 'Collector',
                            "name": 'Muhammad Miraz Mahmud',
                            "date": "05 Jan’ 22"
                        },
                    ],
                    "speakers":
                    {
                        "locality": 'Middle-East Bangal',
                        "roleTitle": 'speaker',
                        "role": [
                            {
                                "id": "22",
                                "name": 'Raihan Mozumdar',
                                "gender": "male"
                            },
                            {
                                "id": "223",
                                "name": 'Bilkis Khatun',
                                "gender": "female"
                            }
                        ]
                    },
                    "audioChecker": {
                        "id": "55",
                        "locality": "North-East  Bangla",
                        "name": "Zakir Hossain",
                        "role": 'Audio Checker',
                        "pickedDate": "23 Jan’23; 3:30 PM"
                    }
                }
            },
            "history": [
                {
                    "lastEdited": '01/02/2022',
                    "status": "Reassigned",
                    "name": "Md. Jalal Uddin",
                    "role": "Admin",
                    "remark": [
                        {
                            "roleInfo": {
                                "id": "maksudalam@gmail.com",
                                "name": "Maksud Alam",
                                "role": "Manager",
                                "gender": "Male",
                            },
                            "deadline": "05/02/2022, 5:41 AM",
                            "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                            "status": "Claim Approved"
                        },
                        {
                            "id": "385",
                            "roleInfo": {
                                "id": "maksudalam@gmail.com",
                                "name": "Maksud Alam",
                                "role": "Manager",
                                "gender": "Male",
                            },
                            "deadline": "05/02/2022, 5:41 AM",
                            "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                            "status": "Cancelled"
                        },
                    ]
                }
            ]
        },
        {
            "id": "1vbuy546yb4y5b",
            "deadline": "01/01/2022",
            "speech": {
                "id": '0',
                "title": "Brahms,Two Pianos",
                "duration": "5:00",
                "url":
                    "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
            },
            "speaker": {
                "locality": "North-East Bangla",
                "speakers": [
                    {
                        "id": "bilkis@gmail.com",
                        "name": 'Bilkis banu',
                        "role": 'speaker',
                        "gender": 'female',
                        "childhoodArea": "Nator",
                        "age": "27",
                        "profession": "teacher",
                        "economicSituation": "poor",
                        "education": "secondary",
                        "smokingHabit": "Prefer not say",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is"
                    },
                    {
                        "id": "maksud@gmail.com",
                        "name": 'maksud alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "barishal",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                    {
                        "id": "rakib@gmail.com",
                        "name": 'rakib alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "rangpur",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                ],

            },
            "audioChecker": {
                "status": "Checking",
                "name": "Peter Bailish",
                "role": "annotator",
                "locality": "Middle-East Bangla",
                "time": "01 Feb ; 3:30 PM"
            },
            "status": "Claim Declined",
            "script": {
                "module": "STT",
                "distributionSource": "Lecture",
                "isAge": false,
                "domain": "Applied science",
                "subdomain": "Technology",

                "sourceType": "",
                "sourceUrl": "",
                "sourceFile": "https://fastly.picsum.photos/id/923/200/300.jpg?hmac=eiYSYaG7v46VlrE38Amrg33bd2FzVjaCsQrLMdekyAU",
                "sourceFileName": "",
                // "sourceFileImage": "https://fastly.picsum.photos/id/923/200/300.jpg?hmac=eiYSYaG7v46VlrE38Amrg33bd2FzVjaCsQrLMdekyAU",
                "title": "ভুল বাক্য ",
                "description": "মহেশপুর থানার ওসি সাইফুল ইসলাম বলেন, খবর পেয়ে পুলিশ ঘটনাস্থলে পৌঁছে মরদেহটি উদ্ধার করে থানায় নিয়ে আসে। পঁয়ত্রিশ - চল্লিশ বছর বয়সী এই নারীর পরনে সালোয়ার কামিজ রয়েছে।",
                "date": "2023-02-16T12:45:58.536350",
                "frequency": 0,
                "id": "a9750dad-0e9b-4d23-9585-c7ff249616e9_20"
            },
            "deadLine": "01/02/2022",
            "submissionDate": "01/02/2022",
            "remark": [
                {
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Claim Approved"
                },
                {
                    "id": "385",
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Cancelled"
                },
            ],
            "others": {
                "device": 'Redmi-Note 7 pro',
                "journey": {
                    "role": [
                        {
                            "id": "1",
                            "role": 'Admin',
                            "name": 'Md. Jalal Uddin',
                            "date": "01 Jan’ 22"
                        },
                        {
                            "id": "2",
                            "role": 'Manager',
                            "name": 'Md. Eman Hassan',
                            "date": "03 Jan’ 22"
                        },
                        {
                            "id": "3",
                            "role": 'Team Leader',
                            "name": 'Jahida Ferdous Mim',
                            "date": "05 Jan’ 22"
                        },
                        {
                            "id": "4",
                            "role": 'Collector',
                            "name": 'Muhammad Miraz Mahmud',
                            "date": "05 Jan’ 22"
                        },
                    ],
                    "speakers":
                    {
                        "locality": 'Middle-East Bangal',
                        "roleTitle": 'speaker',
                        "role": [
                            {
                                "id": "22",
                                "name": 'Raihan Mozumdar',
                                "gender": "male"
                            },
                            {
                                "id": "223",
                                "name": 'Bilkis Khatun',
                                "gender": "female"
                            }
                        ]
                    },
                    "audioChecker": {
                        "id": "55",
                        "locality": "North-East  Bangla",
                        "name": "Zakir Hossain",
                        "role": 'Audio Checker',
                        "pickedDate": "23 Jan’23; 3:30 PM"
                    }
                }
            },
            "history": [
                {
                    "lastEdited": '01/02/2022',
                    "status": "Reassigned",
                    "name": "Md. Jalal Uddin",
                    "role": "Admin",
                    "remark": [
                        {
                            "roleInfo": {
                                "id": "maksudalam@gmail.com",
                                "name": "Maksud Alam",
                                "role": "Manager",
                                "gender": "Male",
                            },
                            "deadline": "05/02/2022, 5:41 AM",
                            "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                            "status": "Claim Approved"
                        },
                        {
                            "id": "385",
                            "roleInfo": {
                                "id": "maksudalam@gmail.com",
                                "name": "Maksud Alam",
                                "role": "Manager",
                                "gender": "Male",
                            },
                            "deadline": "05/02/2022, 5:41 AM",
                            "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                            "status": "Cancelled"
                        },
                    ]
                }
            ]
        },
    ]
}

export const annotationData = {
    total_data_size: 500,
    data: [
        {
            "id": "1b254626234t62456b25",
            "speech": {
                "id": '0',
                "title": "Brahms,Two Pianos",
                "duration": "5:00",
                "url":
                    "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
            },
            "script": {
                "module": "STT",
                "distributionSource": "Lecture",
                "isAge": false,
                "domain": "Applied science",
                "subdomain": "Technology",

                "sourceType": "",
                "sourceUrl": "",
                "sourceFile": "",
                "sourceFileName": "",
                "sourceFileImage": "",
                "title": "",
                "description": "মহেশপুর থানার ওসি সাইফুল ইসলাম বলেন, খবর পেয়ে পুলিশ ঘটনাস্থলে পৌঁছে মরদেহটি উদ্ধার করে থানায় নিয়ে আসে। পঁয়ত্রিশ - চল্লিশ বছর বয়সী এই নারীর পরনে সালোয়ার কামিজ রয়েছে।",
                "date": "2023-02-16T12:45:58.536350",
                "frequency": 0,
                "id": "a9750dad-0e9b-4d23-9585-c7ff249616e9_20"
            },
            "speaker": {
                "locality": "North-East Bangla",
                "speakers": [
                    {
                        "id": "bilkis@gmail.com",
                        "name": 'Bilkis banu',
                        "role": 'speaker',
                        "gender": 'female',
                        "childhoodArea": "Nator",
                        "age": "27",
                        "profession": "teacher",
                        "economicSituation": "poor",
                        "education": "secondary",
                        "smokingHabit": "Prefer not say",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is"
                    },
                    {
                        "id": "maksud@gmail.com",
                        "name": 'maksud alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "barishal",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                    {
                        "id": "rakib@gmail.com",
                        "name": 'rakib alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "rangpur",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                ],

            },
            "wordAnnotation": false,
            "phonemeAnnotation": false,
            "others": {
                "device": 'Redmi-Note 7 pro',
                "journey": {
                    "role": [
                        {
                            "id": "1",
                            "role": 'Admin',
                            "name": 'Md. Jalal Uddin',
                            "date": "01 Jan’ 22"
                        },
                        {
                            "id": "2",
                            "role": 'Manager',
                            "name": 'Md. Eman Hassan',
                            "date": "03 Jan’ 22"
                        },
                        {
                            "id": "3",
                            "role": 'Team Leader',
                            "name": 'Jahida Ferdous Mim',
                            "date": "05 Jan’ 22"
                        },
                        {
                            "id": "4",
                            "role": 'Collector',
                            "name": 'Muhammad Miraz Mahmud',
                            "date": "05 Jan’ 22"
                        },
                    ],
                    "speakers":
                    {
                        "locality": 'Middle-East Bangal',
                        "roleTitle": 'speaker',
                        "role": [
                            {
                                "id": "22",
                                "name": 'Raihan Mozumdar',
                                "gender": "male"
                            },
                            {
                                "id": "223",
                                "name": 'Bilkis Khatun',
                                "gender": "female"
                            }
                        ]
                    },
                    "audioChecker": {
                        "id": "55",
                        "locality": "North-East  Bangla",
                        "name": "Zakir Hossain",
                        "role": 'Audio Checker',
                        "pickedDate": "23 Jan’23; 3:30 PM"
                    }
                }
            },
            "deadLine": "01/02/2022",
            "remark": [
                {
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Claim Approved"
                },
                {
                    "id": "385",
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Cancelled"
                },
            ],

        },
        {
            "id": "2b456254bt6256",
            "speech": {
                "id": '0',
                "title": "Brahms,Two Pianos",
                "duration": "5:00",
                "url":
                    "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
            },
            "script": {
                "module": "STT",
                "distributionSource": "Lecture",
                "isAge": false,
                "domain": "Applied science",
                "subdomain": "Technology",

                "sourceType": "",
                "sourceUrl": "",
                "sourceFile": "",
                "sourceFileName": "",
                "sourceFileImage": "",
                "title": "",
                "description": "মহেশপুর থানার ওসি সাইফুল ইসলাম বলেন, খবর পেয়ে পুলিশ ঘটনাস্থলে পৌঁছে মরদেহটি উদ্ধার করে থানায় নিয়ে আসে। পঁয়ত্রিশ - চল্লিশ বছর বয়সী এই নারীর পরনে সালোয়ার কামিজ রয়েছে।",
                "date": "2023-02-16T12:45:58.536350",
                "frequency": 0,
                "id": "a9750dad-0e9b-4d23-9585-c7ff249616e9_20"
            },
            "speaker": {
                "locality": "North-East Bangla",
                "speakers": [
                    {
                        "id": "bilkis@gmail.com",
                        "name": 'Bilkis banu',
                        "role": 'speaker',
                        "gender": 'female',
                        "childhoodArea": "Nator",
                        "age": "27",
                        "profession": "teacher",
                        "economicSituation": "poor",
                        "education": "secondary",
                        "smokingHabit": "Prefer not say",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is"
                    },
                    {
                        "id": "maksud@gmail.com",
                        "name": 'maksud alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "barishal",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                    {
                        "id": "rakib@gmail.com",
                        "name": 'rakib alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "rangpur",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                ],

            },
            "wordAnnotation": false,
            "phonemeAnnotation": false,
            "others": {
                "device": 'Redmi-Note 7 pro',
                "journey": {
                    "role": [
                        {
                            "id": "1",
                            "role": 'Admin',
                            "name": 'Md. Jalal Uddin',
                            "date": "01 Jan’ 22"
                        },
                        {
                            "id": "2",
                            "role": 'Manager',
                            "name": 'Md. Eman Hassan',
                            "date": "03 Jan’ 22"
                        },
                        {
                            "id": "3",
                            "role": 'Team Leader',
                            "name": 'Jahida Ferdous Mim',
                            "date": "05 Jan’ 22"
                        },
                        {
                            "id": "4",
                            "role": 'Collector',
                            "name": 'Muhammad Miraz Mahmud',
                            "date": "05 Jan’ 22"
                        },
                    ],
                    "speakers":
                    {
                        "locality": 'Middle-East Bangal',
                        "roleTitle": 'speaker',
                        "role": [
                            {
                                "id": "22",
                                "name": 'Raihan Mozumdar',
                                "gender": "male"
                            },
                            {
                                "id": "223",
                                "name": 'Bilkis Khatun',
                                "gender": "female"
                            }
                        ]
                    },
                    "audioChecker": {
                        "id": "55",
                        "locality": "North-East  Bangla",
                        "name": "Zakir Hossain",
                        "role": 'Audio Checker',
                        "pickedDate": "23 Jan’23; 3:30 PM"
                    }
                }
            },
            "deadLine": "01/02/2022",
            "remark": [
                {
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Claim Approved"
                },
                {
                    "id": "385",
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Cancelled"
                },
            ]
        },
        {
            "id": "3b45t5tb54tb45",
            "speech": {
                "id": '0',
                "title": "Brahms,Two Pianos",
                "duration": "5:00",
                "url":
                    "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
            },
            "script": {
                "module": "STT",
                "distributionSource": "Lecture",
                "isAge": false,
                "domain": "Applied science",
                "subdomain": "Technology",

                "sourceType": "",
                "sourceUrl": "",
                "sourceFile": "",
                "sourceFileName": "",
                "sourceFileImage": "",
                "title": "",
                "description": "মহেশপুর থানার ওসি সাইফুল ইসলাম বলেন, খবর পেয়ে পুলিশ ঘটনাস্থলে পৌঁছে মরদেহটি উদ্ধার করে থানায় নিয়ে আসে। পঁয়ত্রিশ - চল্লিশ বছর বয়সী এই নারীর পরনে সালোয়ার কামিজ রয়েছে।",
                "date": "2023-02-16T12:45:58.536350",
                "frequency": 0,
                "id": "a9750dad-0e9b-4d23-9585-c7ff249616e9_20"
            },
            "speaker": {
                "locality": "North-East Bangla",
                "speakers": [
                    {
                        "id": "bilkis@gmail.com",
                        "name": 'Bilkis banu',
                        "role": 'speaker',
                        "gender": 'female',
                        "childhoodArea": "Nator",
                        "age": "27",
                        "profession": "teacher",
                        "economicSituation": "poor",
                        "education": "secondary",
                        "smokingHabit": "Prefer not say",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is"
                    },
                    {
                        "id": "maksud@gmail.com",
                        "name": 'maksud alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "barishal",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                    {
                        "id": "rakib@gmail.com",
                        "name": 'rakib alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "rangpur",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                ],

            },
            "wordAnnotation": false,
            "phonemeAnnotation": false,
            "others": {
                "device": 'Redmi-Note 7 pro',
                "journey": {
                    "role": [
                        {
                            "id": "1",
                            "role": 'Admin',
                            "name": 'Md. Jalal Uddin',
                            "date": "01 Jan’ 22"
                        },
                        {
                            "id": "2",
                            "role": 'Manager',
                            "name": 'Md. Eman Hassan',
                            "date": "03 Jan’ 22"
                        },
                        {
                            "id": "3",
                            "role": 'Team Leader',
                            "name": 'Jahida Ferdous Mim',
                            "date": "05 Jan’ 22"
                        },
                        {
                            "id": "4",
                            "role": 'Collector',
                            "name": 'Muhammad Miraz Mahmud',
                            "date": "05 Jan’ 22"
                        },
                    ],
                    "speakers":
                    {
                        "locality": 'Middle-East Bangal',
                        "roleTitle": 'speaker',
                        "role": [
                            {
                                "id": "22",
                                "name": 'Raihan Mozumdar',
                                "gender": "male"
                            },
                            {
                                "id": "223",
                                "name": 'Bilkis Khatun',
                                "gender": "female"
                            }
                        ]
                    },
                    "audioChecker": {
                        "id": "55",
                        "locality": "North-East  Bangla",
                        "name": "Zakir Hossain",
                        "role": 'Audio Checker',
                        "pickedDate": "23 Jan’23; 3:30 PM"
                    }
                }
            },
            "deadLine": "01/02/2022",
            "remark": [
                {
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Claim Approved"
                },
                {
                    "id": "385",
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Cancelled"
                },
            ]
        },
        {
            "id": "4b2542y546t254b",
            "speech": {
                "id": '0',
                "title": "Brahms,Two Pianos",
                "duration": "5:00",
                "url":
                    "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
            },
            "script": {
                "module": "STT",
                "distributionSource": "Lecture",
                "isAge": false,
                "domain": "Applied science",
                "subdomain": "Technology",

                "sourceType": "",
                "sourceUrl": "",
                "sourceFile": "",
                "sourceFileName": "",
                "sourceFileImage": "",
                "title": "",
                "description": "মহেশপুর থানার ওসি সাইফুল ইসলাম বলেন, খবর পেয়ে পুলিশ ঘটনাস্থলে পৌঁছে মরদেহটি উদ্ধার করে থানায় নিয়ে আসে। পঁয়ত্রিশ - চল্লিশ বছর বয়সী এই নারীর পরনে সালোয়ার কামিজ রয়েছে।",
                "date": "2023-02-16T12:45:58.536350",
                "frequency": 0,
                "id": "a9750dad-0e9b-4d23-9585-c7ff249616e9_20"
            },
            "speaker": {
                "locality": "North-East Bangla",
                "speakers": [
                    {
                        "id": "bilkis@gmail.com",
                        "name": 'Bilkis banu',
                        "role": 'speaker',
                        "gender": 'female',
                        "childhoodArea": "Nator",
                        "age": "27",
                        "profession": "teacher",
                        "economicSituation": "poor",
                        "education": "secondary",
                        "smokingHabit": "Prefer not say",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is"
                    },
                    {
                        "id": "maksud@gmail.com",
                        "name": 'maksud alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "barishal",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                    {
                        "id": "rakib@gmail.com",
                        "name": 'rakib alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "rangpur",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                ],

            },
            "wordAnnotation": false,
            "phonemeAnnotation": false,
            "others": {
                "device": 'Redmi-Note 7 pro',
                "journey": {
                    "role": [
                        {
                            "id": "1",
                            "role": 'Admin',
                            "name": 'Md. Jalal Uddin',
                            "date": "01 Jan’ 22"
                        },
                        {
                            "id": "2",
                            "role": 'Manager',
                            "name": 'Md. Eman Hassan',
                            "date": "03 Jan’ 22"
                        },
                        {
                            "id": "3",
                            "role": 'Team Leader',
                            "name": 'Jahida Ferdous Mim',
                            "date": "05 Jan’ 22"
                        },
                        {
                            "id": "4",
                            "role": 'Collector',
                            "name": 'Muhammad Miraz Mahmud',
                            "date": "05 Jan’ 22"
                        },
                    ],
                    "speakers":
                    {
                        "locality": 'Middle-East Bangal',
                        "roleTitle": 'speaker',
                        "role": [
                            {
                                "id": "22",
                                "name": 'Raihan Mozumdar',
                                "gender": "male"
                            },
                            {
                                "id": "223",
                                "name": 'Bilkis Khatun',
                                "gender": "female"
                            }
                        ]
                    },
                    "audioChecker": {
                        "id": "55",
                        "locality": "North-East  Bangla",
                        "name": "Zakir Hossain",
                        "role": 'Audio Checker',
                        "pickedDate": "23 Jan’23; 3:30 PM"
                    }
                }
            },
            "deadLine": "01/02/2022",
            "remark": [
                {
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Claim Approved"
                },
                {
                    "id": "385",
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Cancelled"
                },
            ]
        },
    ]
}

export const collectAnnSenData = {
    total_data_size: 500,
    data: [
        {
            "id": "1b45254t6b23623",
            "speech": {
                "id": '0',
                "title": "Brahms,Two Pianos",
                "duration": "5:00",
                "url":
                    "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
            },
            "annotate": {
                "status": "Annotating",
                "roleName": "Maksud Alam",
                "locality": "Barendri"
            },
            "deadLine": "01/02/2022",
            "audioChecker": {
                "status": "Checking",
                "name": "Peter Bailish",
                "role": "annotator",
                "locality": "Middle-East Bangla",
                "time": "01 Feb ; 3:30 PM"
            },
            "script": {
                "module": "STT",
                "distributionSource": "Lecture",
                "isAge": false,
                "domain": "Applied science",
                "subdomain": "Technology",
                "sourceType": "",
                "sourceUrl": "",
                "sourceFile": "",
                "sourceFileName": "",
                // "sourceFileImage": "",
                "title": "",
                "description": "মহেশপুর থানার ওসি সাইফুল ইসলাম বলেন, খবর পেয়ে পুলিশ ঘটনাস্থলে পৌঁছে মরদেহটি উদ্ধার করে থানায় নিয়ে আসে। পঁয়ত্রিশ - চল্লিশ বছর বয়সী এই নারীর পরনে সালোয়ার কামিজ রয়েছে।",
                "date": "2023-02-16T12:45:58.536350",
                // "frequency": 0,
                "id": "a9750dad-0e9b-4d23-9585-c7ff249616e9_20"
            },
            "speaker": {
                "locality": "North-East Bangla",
                "speakers": [
                    {
                        "id": "bilkis@gmail.com",
                        "name": 'Bilkis banu',
                        "role": 'speaker',
                        "gender": 'female',
                        "childhoodArea": "Nator",
                        "age": "27",
                        "profession": "teacher",
                        "economicSituation": "poor",
                        "education": "secondary",
                        "smokingHabit": "Prefer not say",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is"
                    },
                    {
                        "id": "maksud@gmail.com",
                        "name": 'maksud alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "barishal",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                    {
                        "id": "rakib@gmail.com",
                        "name": 'rakib alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "rangpur",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                ],

            },
            "remark": [
                {
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Claim Approved"
                },
                {
                    "id": "385",
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Cancelled"
                },
            ],
            "others": {
                "device": 'Redmi-Note 7 pro',
                "journey": {
                    "role": [
                        {
                            "id": "1",
                            "role": 'Admin',
                            "name": 'Md. Jalal Uddin',
                            "date": "01 Jan’ 22"
                        },
                        {
                            "id": "2",
                            "role": 'Manager',
                            "name": 'Md. Eman Hassan',
                            "date": "03 Jan’ 22"
                        },
                        {
                            "id": "3",
                            "role": 'Team Leader',
                            "name": 'Jahida Ferdous Mim',
                            "date": "05 Jan’ 22"
                        },
                        {
                            "id": "4",
                            "role": 'Collector',
                            "name": 'Muhammad Miraz Mahmud',
                            "date": "05 Jan’ 22"
                        },
                    ],
                    "speakers":
                    {
                        "locality": 'Middle-East Bangal',
                        "roleTitle": 'speaker',
                        "role": [
                            {
                                "id": "22",
                                "name": 'Raihan Mozumdar',
                                "gender": "male"
                            },
                            {
                                "id": "223",
                                "name": 'Bilkis Khatun',
                                "gender": "female"
                            }
                        ]
                    },
                    "audioChecker": {
                        "id": "55",
                        "locality": "North-East  Bangla",
                        "name": "Zakir Hossain",
                        "role": 'Audio Checker',
                        "pickedDate": "23 Jan’23; 3:30 PM"
                    }
                }
            },
        },
        {
            "id": "b35t62t625t2",
            "speech": {
                "id": '0',
                "title": "Brahms,Two Pianos",
                "duration": "5:00",
                "url":
                    "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
            },
            "annotate": {
                "status": "Took a Break",
                "roleName": "Maksud Alam",
                "locality": "Barendri"
            },
            "deadLine": "01/02/2022",
            "audioChecker": {
                "status": "Checking",
                "name": "Peter Bailish",
                "role": "audio checker",
                "locality": "Middle-East Bangla",
                "time": "01 Feb ; 3:30 PM"
            },
            "script": {
                "module": "STT",
                "distributionSource": "Lecture",
                "isAge": false,
                "domain": "Applied science",
                "subdomain": "Technology",
                "sourceType": "",
                "sourceUrl": "",
                "sourceFile": "",
                "sourceFileName": "",
                // "sourceFileImage": "",
                "title": "",
                "description": "মহেশপুর থানার ওসি সাইফুল ইসলাম বলেন, খবর পেয়ে পুলিশ ঘটনাস্থলে পৌঁছে মরদেহটি উদ্ধার করে থানায় নিয়ে আসে। পঁয়ত্রিশ - চল্লিশ বছর বয়সী এই নারীর পরনে সালোয়ার কামিজ রয়েছে।",
                "date": "2023-02-16T12:45:58.536350",
                // "frequency": 0,
                "id": "a9750dad-0e9b-4d23-9585-c7ff249616e9_20"
            },
            "speaker": {
                "locality": "North-East Bangla",
                "speakers": [
                    {
                        "id": "bilkis@gmail.com",
                        "name": 'Bilkis banu',
                        "role": 'speaker',
                        "gender": 'female',
                        "childhoodArea": "Nator",
                        "age": "27",
                        "profession": "teacher",
                        "economicSituation": "poor",
                        "education": "secondary",
                        "smokingHabit": "Prefer not say",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is"
                    },
                    {
                        "id": "maksud@gmail.com",
                        "name": 'maksud alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "barishal",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                    {
                        "id": "rakib@gmail.com",
                        "name": 'rakib alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "rangpur",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                ],

            },
            "remark": [
                {
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Claim Approved"
                },
                {
                    "id": "385",
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Cancelled"
                },
            ],
            "others": {
                "device": 'Redmi-Note 7 pro',
                "journey": {
                    "role": [
                        {
                            "id": "1",
                            "role": 'Admin',
                            "name": 'Md. Jalal Uddin',
                            "date": "01 Jan’ 22"
                        },
                        {
                            "id": "2",
                            "role": 'Manager',
                            "name": 'Md. Eman Hassan',
                            "date": "03 Jan’ 22"
                        },
                        {
                            "id": "3",
                            "role": 'Team Leader',
                            "name": 'Jahida Ferdous Mim',
                            "date": "05 Jan’ 22"
                        },
                        {
                            "id": "4",
                            "role": 'Collector',
                            "name": 'Muhammad Miraz Mahmud',
                            "date": "05 Jan’ 22"
                        },
                    ],
                    "speakers":
                    {
                        "locality": 'Middle-East Bangal',
                        "roleTitle": 'speaker',
                        "role": [
                            {
                                "id": "22",
                                "name": 'Raihan Mozumdar',
                                "gender": "male"
                            },
                            {
                                "id": "223",
                                "name": 'Bilkis Khatun',
                                "gender": "female"
                            }
                        ]
                    },
                    "audioChecker": {
                        "id": "55",
                        "locality": "North-East  Bangla",
                        "name": "Zakir Hossain",
                        "role": 'Audio Checker',
                        "pickedDate": "23 Jan’23; 3:30 PM"
                    }
                }
            },
        },
        {
            "id": "3b25625462653",
            "speech": {
                "id": '0',
                "title": "Brahms,Two Pianos",
                "duration": "5:00",
                "url":
                    "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
            },
            "annotate": {
                "status": "Annotating",
                "roleName": "Maksud Alam",
                "locality": "Barendri"
            },
            "deadLine": "01/02/2022",
            "audioChecker": {
                "status": "Checking",
                "name": "Peter Bailish",
                "role": "annotator",
                "locality": "Middle-East Bangla",
                "time": "01 Feb ; 3:30 PM"
            },
            "script": {
                "module": "STT",
                "distributionSource": "Lecture",
                "isAge": false,
                "domain": "Applied science",
                "subdomain": "Technology",
                "sourceType": "",
                "sourceUrl": "",
                "sourceFile": "",
                "sourceFileName": "",
                // "sourceFileImage": "",
                "title": "",
                "description": "মহেশপুর থানার ওসি সাইফুল ইসলাম বলেন, খবর পেয়ে পুলিশ ঘটনাস্থলে পৌঁছে মরদেহটি উদ্ধার করে থানায় নিয়ে আসে। পঁয়ত্রিশ - চল্লিশ বছর বয়সী এই নারীর পরনে সালোয়ার কামিজ রয়েছে।",
                "date": "2023-02-16T12:45:58.536350",
                // "frequency": 0,
                "id": "a9750dad-0e9b-4d23-9585-c7ff249616e9_20"
            },
            "speaker": {
                "locality": "North-East Bangla",
                "speakers": [
                    {
                        "id": "bilkis@gmail.com",
                        "name": 'Bilkis banu',
                        "role": 'speaker',
                        "gender": 'female',
                        "childhoodArea": "Nator",
                        "age": "27",
                        "profession": "teacher",
                        "economicSituation": "poor",
                        "education": "secondary",
                        "smokingHabit": "Prefer not say",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is"
                    },
                    {
                        "id": "maksud@gmail.com",
                        "name": 'maksud alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "barishal",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                    {
                        "id": "rakib@gmail.com",
                        "name": 'rakib alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "rangpur",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                ],

            },
            "remark": [
                {
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Claim Approved"
                },
                {
                    "id": "385",
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Cancelled"
                },
            ],
            "others": {
                "device": 'Redmi-Note 7 pro',
                "journey": {
                    "role": [
                        {
                            "id": "1",
                            "role": 'Admin',
                            "name": 'Md. Jalal Uddin',
                            "date": "01 Jan’ 22"
                        },
                        {
                            "id": "2",
                            "role": 'Manager',
                            "name": 'Md. Eman Hassan',
                            "date": "03 Jan’ 22"
                        },
                        {
                            "id": "3",
                            "role": 'Team Leader',
                            "name": 'Jahida Ferdous Mim',
                            "date": "05 Jan’ 22"
                        },
                        {
                            "id": "4",
                            "role": 'Collector',
                            "name": 'Muhammad Miraz Mahmud',
                            "date": "05 Jan’ 22"
                        },
                    ],
                    "speakers":
                    {
                        "locality": 'Middle-East Bangal',
                        "roleTitle": 'speaker',
                        "role": [
                            {
                                "id": "22",
                                "name": 'Raihan Mozumdar',
                                "gender": "male"
                            },
                            {
                                "id": "223",
                                "name": 'Bilkis Khatun',
                                "gender": "female"
                            }
                        ]
                    },
                    "audioChecker": {
                        "id": "55",
                        "locality": "North-East  Bangla",
                        "name": "Zakir Hossain",
                        "role": 'Audio Checker',
                        "pickedDate": "23 Jan’23; 3:30 PM"
                    }
                }
            },
        },
        {
            "id": "b2546254v6t256vb2454",
            "speech": {
                "id": '0',
                "title": "Brahms,Two Pianos",
                "duration": "5:00",
                "url":
                    "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
            },
            "annotate": {
                "status": "Annotating",
                "roleName": "Maksud Alam",
                "locality": "Barendri"
            },
            "deadLine": "01/02/2022",
            "audioChecker": {
                "status": "Checking",
                "name": "Peter Bailish",
                "role": "annotator",
                "locality": "Middle-East Bangla",
                "time": "01 Feb ; 3:30 PM"
            },
            "script": {
                "module": "STT",
                "distributionSource": "Lecture",
                "isAge": false,
                "domain": "Applied science",
                "subdomain": "Technology",
                "sourceType": "",
                "sourceUrl": "",
                "sourceFile": "",
                "sourceFileName": "",
                // "sourceFileImage": "",
                "title": "",
                "description": "মহেশপুর থানার ওসি সাইফুল ইসলাম বলেন, খবর পেয়ে পুলিশ ঘটনাস্থলে পৌঁছে মরদেহটি উদ্ধার করে থানায় নিয়ে আসে। পঁয়ত্রিশ - চল্লিশ বছর বয়সী এই নারীর পরনে সালোয়ার কামিজ রয়েছে।",
                "date": "2023-02-16T12:45:58.536350",
                // "frequency": 0,
                "id": "a9750dad-0e9b-4d23-9585-c7ff249616e9_20"
            },
            "speaker": {
                "locality": "North-East Bangla",
                "speakers": [
                    {
                        "id": "bilkis@gmail.com",
                        "name": 'Bilkis banu',
                        "role": 'speaker',
                        "gender": 'female',
                        "childhoodArea": "Nator",
                        "age": "27",
                        "profession": "teacher",
                        "economicSituation": "poor",
                        "education": "secondary",
                        "smokingHabit": "Prefer not say",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is"
                    },
                    {
                        "id": "maksud@gmail.com",
                        "name": 'maksud alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "barishal",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                    {
                        "id": "rakib@gmail.com",
                        "name": 'rakib alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "rangpur",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                ],

            },
            "remark": [
                {
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Claim Approved"
                },
                {
                    "id": "385",
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Cancelled"
                },
            ],
            "others": {
                "device": 'Redmi-Note 7 pro',
                "journey": {
                    "role": [
                        {
                            "id": "1",
                            "role": 'Admin',
                            "name": 'Md. Jalal Uddin',
                            "date": "01 Jan’ 22"
                        },
                        {
                            "id": "2",
                            "role": 'Manager',
                            "name": 'Md. Eman Hassan',
                            "date": "03 Jan’ 22"
                        },
                        {
                            "id": "3",
                            "role": 'Team Leader',
                            "name": 'Jahida Ferdous Mim',
                            "date": "05 Jan’ 22"
                        },
                        {
                            "id": "4",
                            "role": 'Collector',
                            "name": 'Muhammad Miraz Mahmud',
                            "date": "05 Jan’ 22"
                        },
                    ],
                    "speakers":
                    {
                        "locality": 'Middle-East Bangal',
                        "roleTitle": 'speaker',
                        "role": [
                            {
                                "id": "22",
                                "name": 'Raihan Mozumdar',
                                "gender": "male"
                            },
                            {
                                "id": "223",
                                "name": 'Bilkis Khatun',
                                "gender": "female"
                            }
                        ]
                    },
                    "audioChecker": {
                        "id": "55",
                        "locality": "North-East  Bangla",
                        "name": "Zakir Hossain",
                        "role": 'Audio Checker',
                        "pickedDate": "23 Jan’23; 3:30 PM"
                    }
                }
            },
        },
    ]
}

export const annotatedFiles = {
    total_data_size: 500,
    data: [
        {
            "id": "1b25462v54546",
            "speech": {
                "id": '0',
                "title": "Brahms,Two Pianos",
                "duration": "5:00",
                "url":
                    "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
            },
            "speaker": {
                "locality": "North-East Bangla",
                "speakers": [
                    {
                        "id": "bilkis@gmail.com",
                        "name": 'Bilkis banu',
                        "role": 'speaker',
                        "gender": 'female',
                        "childhoodArea": "Nator",
                        "age": "27",
                        "profession": "teacher",
                        "economicSituation": "poor",
                        "education": "secondary",
                        "smokingHabit": "Prefer not say",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is"
                    },
                    {
                        "id": "maksud@gmail.com",
                        "name": 'maksud alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "barishal",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                    {
                        "id": "rakib@gmail.com",
                        "name": 'rakib alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "rangpur",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                ],

            },
            "others": {
                "device": 'Redmi-Note 7 pro',
                "journey": {
                    "role": [
                        {
                            "id": "1",
                            "role": 'Admin',
                            "name": 'Md. Jalal Uddin',
                            "date": "01 Jan’ 22"
                        },
                        {
                            "id": "2",
                            "role": 'Manager',
                            "name": 'Md. Eman Hassan',
                            "date": "03 Jan’ 22"
                        },
                        {
                            "id": "3",
                            "role": 'Team Leader',
                            "name": 'Jahida Ferdous Mim',
                            "date": "05 Jan’ 22"
                        },
                        {
                            "id": "4",
                            "role": 'Collector',
                            "name": 'Muhammad Miraz Mahmud',
                            "date": "05 Jan’ 22"
                        },
                    ],
                    "speakers":
                    {
                        "locality": 'Middle-East Bangal',
                        "roleTitle": 'speaker',
                        "role": [
                            {
                                "id": "22",
                                "name": 'Raihan Mozumdar',
                                "gender": "male"
                            },
                            {
                                "id": "223",
                                "name": 'Bilkis Khatun',
                                "gender": "female"
                            }
                        ]
                    },
                    "audioChecker": {
                        "id": "55",
                        "locality": "North-East  Bangla",
                        "name": "Zakir Hossain",
                        "role": 'Audio Checker',
                        "pickedDate": "23 Jan’23; 3:30 PM"
                    }
                }
            },
            "history": [
                {
                    "id": "123",
                    "lastEdited": '01/02/2022',
                    "status": "Reassigned",
                    "name": "Md. Jalal Uddin",
                    "role": "Admin",
                    "remark": [
                        {
                            "id": "333",
                            "roleInfo": {
                                "id": "maksudalam@gmail.com",
                                "name": "Maksud Alam",
                                "role": "Manager",
                                "gender": "Male",
                            },
                            "deadline": "05/02/2022, 5:41 AM",
                            "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                            "status": "Claim Approved"
                        },
                        {
                            "id": "385",
                            "roleInfo": {
                                "id": "maksudalam@gmail.com",
                                "name": "Maksud Alam",
                                "role": "Manager",
                                "gender": "Male",
                            },
                            "deadline": "05/02/2022, 5:41 AM",
                            "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                            "status": "Cancelled"
                        },
                    ]
                }
            ],
            "audioChecker": {
                "status": "Checking",
                "locality": "Middle-East Bangla",
                "time": "01 Feb ; 3:30 PM",
                "audioCheckers": {
                    "id": "maksudalam2@gmail.com",
                    "name": 'Bilkis banu',
                    "role": 'audio Checker',
                    "gender": 'female',
                },
            },
            "annotator": {
                "status": "",
                "locality": "Barendri",
                "time": "01 Feb ; 3:30 PM",
                "annotator": {
                    "id": "maksudalam2@gmail.com",
                    "name": 'Bilkis banu',
                    "role": 'annotator',
                    "gender": 'female',
                },
            },
            "status": "Cancelled",
            "script": {
                "module": "STT",
                "distributionSource": "Lecture",
                "isAge": false,
                "domain": "Applied science",
                "subdomain": "Technology",

                "sourceType": "",
                "sourceUrl": "",
                "sourceFile": "",
                "sourceFileName": "",
                "sourceFileImage": "",
                "title": "",
                "description": "মহেশপুর থানার ওসি সাইফুল ইসলাম বলেন, খবর পেয়ে পুলিশ ঘটনাস্থলে পৌঁছে মরদেহটি উদ্ধার করে থানায় নিয়ে আসে। পঁয়ত্রিশ - চল্লিশ বছর বয়সী এই নারীর পরনে সালোয়ার কামিজ রয়েছে।",
                "date": "2023-02-16T12:45:58.536350",
                "frequency": 0,
                "id": "a9750dad-0e9b-4d23-9585-c7ff249616e9_20"
            },

            "deadLine": "01/02/2022",
            "submissionDate": "01/02/2022",
            "remark": [
                {
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Claim Approved"
                },
                {
                    "id": "385",
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Cancelled"
                },
            ]
        },
        {
            "id": "2",
            "speech": {
                "id": '0',
                "title": "Brahms,Two Pianos",
                "duration": "5:00",
                "url":
                    "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
            },
            "speaker": {
                "locality": "North-East Bangla",
                "speakers": [
                    {
                        "id": "bilkis@gmail.com",
                        "name": 'Bilkis banu',
                        "role": 'speaker',
                        "gender": 'female',
                        "childhoodArea": "Nator",
                        "age": "27",
                        "profession": "teacher",
                        "economicSituation": "poor",
                        "education": "secondary",
                        "smokingHabit": "Prefer not say",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is"
                    },
                    {
                        "id": "maksud@gmail.com",
                        "name": 'maksud alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "barishal",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                    {
                        "id": "rakib@gmail.com",
                        "name": 'rakib alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "rangpur",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                ],

            },
            "others": {
                "device": 'Redmi-Note 7 pro',
                "journey": {
                    "role": [
                        {
                            "id": "1",
                            "role": 'Admin',
                            "name": 'Md. Jalal Uddin',
                            "date": "01 Jan’ 22"
                        },
                        {
                            "id": "2",
                            "role": 'Manager',
                            "name": 'Md. Eman Hassan',
                            "date": "03 Jan’ 22"
                        },
                        {
                            "id": "3",
                            "role": 'Team Leader',
                            "name": 'Jahida Ferdous Mim',
                            "date": "05 Jan’ 22"
                        },
                        {
                            "id": "4",
                            "role": 'Collector',
                            "name": 'Muhammad Miraz Mahmud',
                            "date": "05 Jan’ 22"
                        },
                    ],
                    "speakers":
                    {
                        "locality": 'Middle-East Bangal',
                        "roleTitle": 'speaker',
                        "role": [
                            {
                                "id": "22",
                                "name": 'Raihan Mozumdar',
                                "gender": "male"
                            },
                            {
                                "id": "223",
                                "name": 'Bilkis Khatun',
                                "gender": "female"
                            }
                        ]
                    },
                    "audioChecker": {
                        "id": "55",
                        "locality": "North-East  Bangla",
                        "name": "Zakir Hossain",
                        "role": 'Audio Checker',
                        "pickedDate": "23 Jan’23; 3:30 PM"
                    }
                }
            },
            "history": [
                {
                    "id": "123",
                    "lastEdited": '01/02/2022',
                    "status": "Reassigned",
                    "name": "Md. Jalal Uddin",
                    "role": "Admin",
                    "remark": [
                        {
                            "id": "333",
                            "roleInfo": {
                                "id": "maksudalam@gmail.com",
                                "name": "Maksud Alam",
                                "role": "Manager",
                                "gender": "Male",
                            },
                            "deadline": "05/02/2022, 5:41 AM",
                            "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                            "status": "Claim Approved"
                        },
                        {
                            "id": "385",
                            "roleInfo": {
                                "id": "maksudalam@gmail.com",
                                "name": "Maksud Alam",
                                "role": "Manager",
                                "gender": "Male",
                            },
                            "deadline": "05/02/2022, 5:41 AM",
                            "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                            "status": "Cancelled"
                        },
                    ]
                },
                {
                    "id": "12124233",
                    "lastEdited": '01/02/2022',
                    "status": "Reassigned",
                    "name": "Md. Jalal Uddin",
                    "role": "Admin",
                    "remark": [
                        {
                            "id": "333",
                            "roleInfo": {
                                "id": "maksudalam@gmail.com",
                                "name": "Maksud Alam",
                                "role": "Manager",
                                "gender": "Male",
                            },
                            "deadline": "05/02/2022, 5:41 AM",
                            "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                            "status": "Claim Approved"
                        },
                        {
                            "id": "385",
                            "roleInfo": {
                                "id": "maksudalam@gmail.com",
                                "name": "Maksud Alam",
                                "role": "Manager",
                                "gender": "Male",
                            },
                            "deadline": "05/02/2022, 5:41 AM",
                            "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                            "status": "Cancelled"
                        },
                    ]
                }
            ],
            "audioChecker": {
                "status": "Checking",
                "locality": "Middle-East Bangla",
                "time": "01 Feb ; 3:30 PM",
                "audioCheckers": {
                    "id": "maksudalam2@gmail.com",
                    "name": 'Bilkis banu',
                    "role": 'audio Checker',
                    "gender": 'female',
                },
            },
            "annotator": {
                "status": "",
                "locality": "Barendri",
                "time": "01 Feb ; 3:30 PM",
                "annotator": {
                    "id": "maksudalam2@gmail.com",
                    "name": 'Bilkis banu',
                    "role": 'annotator',
                    "gender": 'female',
                },
            },
            "status": "Approved",
            "script": {
                "module": "STT",
                "distributionSource": "Lecture",
                "isAge": false,
                "domain": "Applied science",
                "subdomain": "Technology",

                "sourceType": "",
                "sourceUrl": "",
                "sourceFile": "",
                "sourceFileName": "",
                "sourceFileImage": "",
                "title": "",
                "description": "মহেশপুর থানার ওসি সাইফুল ইসলাম বলেন, খবর পেয়ে পুলিশ ঘটনাস্থলে পৌঁছে মরদেহটি উদ্ধার করে থানায় নিয়ে আসে। পঁয়ত্রিশ - চল্লিশ বছর বয়সী এই নারীর পরনে সালোয়ার কামিজ রয়েছে।",
                "date": "2023-02-16T12:45:58.536350",
                "frequency": 0,
                "id": "a9750dad-0e9b-4d23-9585-c7ff249616e9_20"
            },

            "deadLine": "01/02/2022",
            "submissionDate": "01/02/2022",
            "remark": [
                {
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Claim Approved"
                },
                {
                    "id": "385",
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Cancelled"
                },
            ]
        },
        {
            "id": "3",
            "speech": {
                "id": '0',
                "title": "Brahms,Two Pianos",
                "duration": "5:00",
                "url":
                    "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
            },
            "speaker": {
                "locality": "North-East Bangla",
                "speakers": [
                    {
                        "id": "bilkis@gmail.com",
                        "name": 'Bilkis banu',
                        "role": 'speaker',
                        "gender": 'female',
                        "childhoodArea": "Nator",
                        "age": "27",
                        "profession": "teacher",
                        "economicSituation": "poor",
                        "education": "secondary",
                        "smokingHabit": "Prefer not say",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is"
                    },
                    {
                        "id": "maksud@gmail.com",
                        "name": 'maksud alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "barishal",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                    {
                        "id": "rakib@gmail.com",
                        "name": 'rakib alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "rangpur",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                ],

            },
            "others": {
                "device": 'Redmi-Note 7 pro',
                "journey": {
                    "role": [
                        {
                            "id": "1",
                            "role": 'Admin',
                            "name": 'Md. Jalal Uddin',
                            "date": "01 Jan’ 22"
                        },
                        {
                            "id": "2",
                            "role": 'Manager',
                            "name": 'Md. Eman Hassan',
                            "date": "03 Jan’ 22"
                        },
                        {
                            "id": "3",
                            "role": 'Team Leader',
                            "name": 'Jahida Ferdous Mim',
                            "date": "05 Jan’ 22"
                        },
                        {
                            "id": "4",
                            "role": 'Collector',
                            "name": 'Muhammad Miraz Mahmud',
                            "date": "05 Jan’ 22"
                        },
                    ],
                    "speakers":
                    {
                        "locality": 'Middle-East Bangal',
                        "roleTitle": 'speaker',
                        "role": [
                            {
                                "id": "22",
                                "name": 'Raihan Mozumdar',
                                "gender": "male"
                            },
                            {
                                "id": "223",
                                "name": 'Bilkis Khatun',
                                "gender": "female"
                            }
                        ]
                    },
                    "audioChecker": {
                        "id": "55",
                        "locality": "North-East  Bangla",
                        "name": "Zakir Hossain",
                        "role": 'Audio Checker',
                        "pickedDate": "23 Jan’23; 3:30 PM"
                    }
                }
            },
            "history": [
                {
                    "id": "123",
                    "lastEdited": '01/02/2022',
                    "status": "Reassigned",
                    "name": "Md. Jalal Uddin",
                    "role": "Admin",
                    "remark": [
                        {
                            "id": "333",
                            "roleInfo": {
                                "id": "maksudalam@gmail.com",
                                "name": "Maksud Alam",
                                "role": "Manager",
                                "gender": "Male",
                            },
                            "deadline": "05/02/2022, 5:41 AM",
                            "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                            "status": "Claim Approved"
                        },
                        {
                            "id": "385",
                            "roleInfo": {
                                "id": "maksudalam@gmail.com",
                                "name": "Maksud Alam",
                                "role": "Manager",
                                "gender": "Male",
                            },
                            "deadline": "05/02/2022, 5:41 AM",
                            "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                            "status": "Cancelled"
                        },
                    ]
                }
            ],
            "audioChecker": {
                "status": "Checking",
                "locality": "Middle-East Bangla",
                "time": "01 Feb ; 3:30 PM",
                "audioCheckers": {
                    "id": "maksudalam2@gmail.com",
                    "name": 'Bilkis banu',
                    "role": 'audio Checker',
                    "gender": 'female',
                },
            },
            "annotator": {
                "status": "",
                "locality": "Barendri",
                "time": "01 Feb ; 3:30 PM",
                "annotator": {
                    "id": "maksudalam2@gmail.com",
                    "name": 'Bilkis banu',
                    "role": 'annotator',
                    "gender": 'female',
                },
            },
            "status": "Claimed",
            "script": {
                "module": "STT",
                "distributionSource": "Lecture",
                "isAge": false,
                "domain": "Applied science",
                "subdomain": "Technology",

                "sourceType": "",
                "sourceUrl": "",
                "sourceFile": "",
                "sourceFileName": "",
                "sourceFileImage": "",
                "title": "",
                "description": "মহেশপুর থানার ওসি সাইফুল ইসলাম বলেন, খবর পেয়ে পুলিশ ঘটনাস্থলে পৌঁছে মরদেহটি উদ্ধার করে থানায় নিয়ে আসে। পঁয়ত্রিশ - চল্লিশ বছর বয়সী এই নারীর পরনে সালোয়ার কামিজ রয়েছে।",
                "date": "2023-02-16T12:45:58.536350",
                "frequency": 0,
                "id": "a9750dad-0e9b-4d23-9585-c7ff249616e9_20"
            },

            "deadLine": "01/02/2022",
            "submissionDate": "01/02/2022",
            "remark": [
                {
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Claim Approved"
                },
                {
                    "id": "385",
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Cancelled"
                },
            ]
        },
    ]
}


export const colAnnSenStatusFilterData = [
    STATUS_ANNOTATING,
    STATUS_TOOK_A_BREAK
]

export const colValSenStatusFilterData = [
    STATUS_VALIDATING,
    STATUS_TOOK_A_BREAK
]

export const uploadAudioDataTypeFilterData = [
    STTMODULE,
    TTSMODULE
]


export const collectValSenData = {
    total_data_size: 500,
    data: [
        {
            "id": "vn4yv7yt05tv597vy4",
            "speech": {
                "id": '0',
                "title": "Brahms,Two Pianos",
                "duration": "5:00",
                "url":
                    "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
            },
            "validator1": {
                "status": "Validating",
                "locality": "Barendri",
                "role": "validator",
                "name": "Raihan Rohan",
                "gender": "male"
            },
            "validator2": {
                "status": "",
                "locality": "",
                "role": "validator",
                "name": "Raihan Rohan",
                "gender": "male"
            },
            "validatorFinal": {
                "status": "",
                "locality": "",
                "role": "validator",
                "name": "Raihan Rohan",
                "gender": "male"
            },
            "deadLine": "01/02/2022",
            "annotator": {
                "status": "",
                "locality": "Barendri",
                "time": "01 Feb ; 3:30 PM",
                "annotator": {
                    "id": "maksudalam2@gmail.com",
                    "name": 'Bilkis banu',
                    "role": 'annotator',
                    "gender": 'female',
                },
            },
            "audioChecker": {
                "status": "Checking",
                "locality": "Middle-East Bangla",
                "time": "01 Feb ; 3:30 PM",
                "name": 'Bilkis banu',
                "role": 'audio Checker',
            },
            "speaker": {
                "locality": "North-East Bangla",
                "speakers": [
                    {
                        "id": "bilkis@gmail.com",
                        "name": 'Bilkis banu',
                        "role": 'speaker',
                        "gender": 'female',
                        "childhoodArea": "Nator",
                        "age": "27",
                        "profession": "teacher",
                        "economicSituation": "poor",
                        "education": "secondary",
                        "smokingHabit": "Prefer not say",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is"
                    },
                    {
                        "id": "maksud@gmail.com",
                        "name": 'maksud alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "barishal",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                    {
                        "id": "rakib@gmail.com",
                        "name": 'rakib alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "rangpur",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                ],

            },
            "script": {
                "module": "STT",
                "distributionSource": "Lecture",
                "isAge": false,
                "domain": "Applied science",
                "subdomain": "Technology",
                "sourceType": "",
                "sourceUrl": "",
                "sourceFile": "",
                "sourceFileName": "",
                // "sourceFileImage": "",
                "title": "",
                "description": "মহেশপুর থানার ওসি সাইফুল ইসলাম বলেন, খবর পেয়ে পুলিশ ঘটনাস্থলে পৌঁছে মরদেহটি উদ্ধার করে থানায় নিয়ে আসে। পঁয়ত্রিশ - চল্লিশ বছর বয়সী এই নারীর পরনে সালোয়ার কামিজ রয়েছে।",
                "date": "2023-02-16T12:45:58.536350",
                // "frequency": 0,
                "id": "a9750dad-0e9b-4d23-9585-c7ff249616e9_20"
            },
            "remark": [
                {
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Claim Approved"
                },
                {
                    "id": "385",
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Cancelled"
                },
            ],
            "others": {
                "device": 'Redmi-Note 7 pro',
                "journey": {
                    "role": [
                        {
                            "id": "1",
                            "role": 'Admin',
                            "name": 'Md. Jalal Uddin',
                            "date": "01 Jan’ 22"
                        },
                        {
                            "id": "2",
                            "role": 'Manager',
                            "name": 'Md. Eman Hassan',
                            "date": "03 Jan’ 22"
                        },
                        {
                            "id": "3",
                            "role": 'Team Leader',
                            "name": 'Jahida Ferdous Mim',
                            "date": "05 Jan’ 22"
                        },
                        {
                            "id": "4",
                            "role": 'Collector',
                            "name": 'Muhammad Miraz Mahmud',
                            "date": "05 Jan’ 22"
                        },
                    ],
                    "speakers":
                    {
                        "locality": 'Middle-East Bangal',
                        "roleTitle": 'speaker',
                        "role": [
                            {
                                "id": "22",
                                "name": 'Raihan Mozumdar',
                                "gender": "male"
                            },
                            {
                                "id": "223",
                                "name": 'Bilkis Khatun',
                                "gender": "female"
                            }
                        ]
                    },
                    "audioChecker": {
                        "id": "55",
                        "locality": "North-East  Bangla",
                        "name": "Zakir Hossain",
                        "role": 'Audio Checker',
                        "pickedDate": "23 Jan’23; 3:30 PM"
                    }
                }
            },
        },
    ]
}

export const validatedFilesData = {
    total_data_size: 500,
    data: [
        {
            "id": "ughsdfiouwfhidu",
            "speech": {
                "id": '0',
                "title": "Brahms,Two Pianos",
                "duration": "5:00",
                "url":
                    "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
            },
            "validatorFinal": {
                "locality": "Middle-East Bangla",
                "time": "01 Feb ; 3:30 PM",
                "name": 'Wade Warren',
                "role": 'validator',
                "gender": 'female',
            },
            "status": "Cancelled",
            "validator2": {
                "locality": "Middle-East Bangla",
                "time": "01 Feb ; 3:30 PM",
                "name": 'Wade Warren',
                "role": 'validator',
                "gender": 'female',
            },
            "validator1": {
                "locality": "Middle-East Bangla",
                "time": "01 Feb ; 3:30 PM",
                "name": 'Wade Warren',
                "role": 'validator',
                "gender": 'female',
            },
            "annotator": {
                "status": "",
                "locality": "Barendri",
                "time": "01 Feb ; 3:30 PM",
                "annotator": {
                    "id": "maksudalam2@gmail.com",
                    "name": 'Bilkis banu',
                    "role": 'annotator',
                    "gender": 'female',
                },
            },
            "audioChecker": {
                "status": "Checking",
                "name": "Peter Bailish",
                "role": "annotator",
                "locality": "Middle-East Bangla",
                "time": "01 Feb ; 3:30 PM"
            },
            "speaker": {
                "locality": "North-East Bangla",
                "speakers": [
                    {
                        "id": "bilkis@gmail.com",
                        "name": 'Bilkis banu',
                        "role": 'speaker',
                        "gender": 'female',
                        "childhoodArea": "Nator",
                        "age": "27",
                        "profession": "teacher",
                        "economicSituation": "poor",
                        "education": "secondary",
                        "smokingHabit": "Prefer not say",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is"
                    },
                    {
                        "id": "maksud@gmail.com",
                        "name": 'maksud alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "barishal",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                    {
                        "id": "rakib@gmail.com",
                        "name": 'rakib alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "rangpur",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                ],

            },
            "deadLine": "01/02/2022",
            "submissionDate": "01/02/2022",
            "remark": [
                {
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Claim Approved"
                },
                {
                    "id": "385",
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Cancelled"
                },
            ],
            "script": {
                "module": "STT",
                "distributionSource": "Lecture",
                "isAge": false,
                "domain": "Applied science",
                "subdomain": "Technology",

                "sourceType": "",
                "sourceUrl": "",
                "sourceFile": "",
                "sourceFileName": "",
                "sourceFileImage": "",
                "title": "",
                "description": "মহেশপুর থানার ওসি সাইফুল ইসলাম বলেন, খবর পেয়ে পুলিশ ঘটনাস্থলে পৌঁছে মরদেহটি উদ্ধার করে থানায় নিয়ে আসে। পঁয়ত্রিশ - চল্লিশ বছর বয়সী এই নারীর পরনে সালোয়ার কামিজ রয়েছে।",
                "date": "2023-02-16T12:45:58.536350",
                "frequency": 0,
                "id": "a9750dad-0e9b-4d23-9585-c7ff249616e9_20"
            },
            "others": {
                "device": 'Redmi-Note 7 pro',
                "journey": {
                    "role": [
                        {
                            "id": "1",
                            "role": 'Admin',
                            "name": 'Md. Jalal Uddin',
                            "date": "01 Jan’ 22"
                        },
                        {
                            "id": "2",
                            "role": 'Manager',
                            "name": 'Md. Eman Hassan',
                            "date": "03 Jan’ 22"
                        },
                        {
                            "id": "3",
                            "role": 'Team Leader',
                            "name": 'Jahida Ferdous Mim',
                            "date": "05 Jan’ 22"
                        },
                        {
                            "id": "4",
                            "role": 'Collector',
                            "name": 'Muhammad Miraz Mahmud',
                            "date": "05 Jan’ 22"
                        },
                    ],
                    "speakers":
                    {
                        "locality": 'Middle-East Bangal',
                        "roleTitle": 'speaker',
                        "role": [
                            {
                                "id": "22",
                                "name": 'Raihan Mozumdar',
                                "gender": "male"
                            },
                            {
                                "id": "223",
                                "name": 'Bilkis Khatun',
                                "gender": "female"
                            }
                        ]
                    },
                    "audioChecker": {
                        "id": "55",
                        "locality": "North-East  Bangla",
                        "name": "Zakir Hossain",
                        "role": 'Audio Checker',
                        "pickedDate": "23 Jan’23; 3:30 PM"
                    }
                }
            },
            "history": [
                {
                    "id": "123",
                    "lastEdited": '01/02/2022',
                    "status": "Reassigned",
                    "name": "Md. Jalal Uddin",
                    "role": "Admin",
                    "remark": [
                        {
                            "id": "333",
                            "roleInfo": {
                                "id": "maksudalam@gmail.com",
                                "name": "Maksud Alam",
                                "role": "Manager",
                                "gender": "Male",
                                "date": "08/02/2022, 4:31 PM"
                            },
                            "deadline": "05/02/2022, 5:41 AM",
                            "des": "Dark UX is when designers create an experience that        pushes users in a direction that benefits the interests of the company",
                            "status": "Claim Approved"
                        },
                    ]
                }
            ]
        },
        {
            "id": "ughsdfifhidu",
            "speech": {
                "id": '0',
                "title": "Brahms,Two Pianos",
                "duration": "5:00",
                "url":
                    "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
            },
            "validatorFinal": {
                "locality": "Middle-East Bangla",
                "time": "01 Feb ; 3:30 PM",
                "name": 'Wade Warren',
                "role": 'validator',
                "gender": 'female',
            },
            "status": "Cancelled",
            "validator2": {
                "locality": "Middle-East Bangla",
                "time": "01 Feb ; 3:30 PM",
                "name": 'Wade Warren',
                "role": 'validator',
                "gender": 'female',
            },
            "validator1": {
                "locality": "Middle-East Bangla",
                "time": "01 Feb ; 3:30 PM",
                "name": 'Wade Warren',
                "role": 'validator',
                "gender": 'female',
            },
            "annotator": {
                "status": "",
                "locality": "Barendri",
                "time": "01 Feb ; 3:30 PM",
                "annotator": {
                    "id": "maksudalam2@gmail.com",
                    "name": 'Bilkis banu',
                    "role": 'annotator',
                    "gender": 'female',
                },
            },
            "audioChecker": {
                "status": "Checking",
                "name": "Peter Bailish",
                "role": "annotator",
                "locality": "Middle-East Bangla",
                "time": "01 Feb ; 3:30 PM"
            },
            "speaker": {
                "locality": "North-East Bangla",
                "speakers": [
                    {
                        "id": "bilkis@gmail.com",
                        "name": 'Bilkis banu',
                        "role": 'speaker',
                        "gender": 'female',
                        "childhoodArea": "Nator",
                        "age": "27",
                        "profession": "teacher",
                        "economicSituation": "poor",
                        "education": "secondary",
                        "smokingHabit": "Prefer not say",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is"
                    },
                    {
                        "id": "maksud@gmail.com",
                        "name": 'maksud alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "barishal",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                    {
                        "id": "rakib@gmail.com",
                        "name": 'rakib alam',
                        "role": 'speaker',
                        "gender": 'male',
                        "childhoodArea": "rangpur",
                        "age": "55",
                        "profession": "Doctor",
                        "economicSituation": "rich",
                        "education": "higher secondary",
                        "smokingHabit": "yes",
                        "hearingDisability": "no",
                        "shutter": "yes",
                        "recordingArea": "Inside Room",
                        "recordingDistance": "Close",
                        "note": "Dark Ux is ---"
                    },
                ],

            },
            "deadLine": "01/02/2022",
            "submissionDate": "01/02/2022",
            "remark": [
                {
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Claim Approved"
                },
                {
                    "id": "385",
                    "roleInfo": {
                        "id": "maksudalam@gmail.com",
                        "name": "Maksud Alam",
                        "role": "Manager",
                        "gender": "Male",
                    },
                    "deadline": "05/02/2022, 5:41 AM",
                    "des": "Dark UX is when designers create an experience that pushes users in a direction that benefits the interests of the company",
                    "status": "Cancelled"
                },
            ],
            "script": {
                "module": "STT",
                "distributionSource": "Lecture",
                "isAge": false,
                "domain": "Applied science",
                "subdomain": "Technology",

                "sourceType": "",
                "sourceUrl": "",
                "sourceFile": "",
                "sourceFileName": "",
                "sourceFileImage": "",
                "title": "",
                "description": "মহেশপুর থানার ওসি সাইফুল ইসলাম বলেন, খবর পেয়ে পুলিশ ঘটনাস্থলে পৌঁছে মরদেহটি উদ্ধার করে থানায় নিয়ে আসে। পঁয়ত্রিশ - চল্লিশ বছর বয়সী এই নারীর পরনে সালোয়ার কামিজ রয়েছে।",
                "date": "2023-02-16T12:45:58.536350",
                "frequency": 0,
                "id": "a9750dad-0e9b-4d23-9585-c7ff249616e9_20"
            },
            "others": {
                "device": 'Redmi-Note 7 pro',
                "journey": {
                    "role": [
                        {
                            "id": "1",
                            "role": 'Admin',
                            "name": 'Md. Jalal Uddin',
                            "date": "01 Jan’ 22"
                        },
                        {
                            "id": "2",
                            "role": 'Manager',
                            "name": 'Md. Eman Hassan',
                            "date": "03 Jan’ 22"
                        },
                        {
                            "id": "3",
                            "role": 'Team Leader',
                            "name": 'Jahida Ferdous Mim',
                            "date": "05 Jan’ 22"
                        },
                        {
                            "id": "4",
                            "role": 'Collector',
                            "name": 'Muhammad Miraz Mahmud',
                            "date": "05 Jan’ 22"
                        },
                    ],
                    "speakers":
                    {
                        "locality": 'Middle-East Bangal',
                        "roleTitle": 'speaker',
                        "role": [
                            {
                                "id": "22",
                                "name": 'Raihan Mozumdar',
                                "gender": "male"
                            },
                            {
                                "id": "223",
                                "name": 'Bilkis Khatun',
                                "gender": "female"
                            }
                        ]
                    },
                    "audioChecker": {
                        "id": "55",
                        "locality": "North-East  Bangla",
                        "name": "Zakir Hossain",
                        "role": 'Audio Checker',
                        "pickedDate": "23 Jan’23; 3:30 PM"
                    }
                }
            },
            "history": [
                {
                    "id": "123",
                    "lastEdited": '01/02/2022',
                    "status": "Reassigned",
                    "name": "Md. Jalal Uddin",
                    "role": "Admin",
                    "remark": [
                        {
                            "id": "333",
                            "roleInfo": {
                                "id": "maksudalam@gmail.com",
                                "name": "Maksud Alam",
                                "role": "Manager",
                                "gender": "Male",
                                "date": "08/02/2022, 4:31 PM"
                            },
                            "deadline": "05/02/2022, 5:41 AM",
                            "des": "Dark UX is when designers create an experience that        pushes users in a direction that benefits the interests of the company",
                            "status": "Claim Approved"
                        },
                    ]
                }
            ]
        }
    ]
}

export const district = [
    "Dhaka",
    "Comilla",
    "Chittagong",
    "Rajshahi",
    "Sylhet",
    "Barisal",
    "Khulna",
    "Mymensingh"
]

export const ageRange = [
    "7-14",
    "15-24",
    "25-34",
    "35-44",
    "45-59",
    "60-65"
]

export const collectedAudioFilterData: targetFilterDT[] = [
    {
        type: "select-with-checkbox",
        key: "script",
        title: "Script",
        //   child: ["STT", "TTS"],
        viewKey: "script",
        isFromAPI: true,
        child: []
    },
    {
        type: "multiple-select",
        key: "collector",
        title: "Collector",
        //   role: "collector",
        viewKey: "collector_details",
        viewRoleImg: "collector",
        selects: [
            {
                type: "select",
                key: "collector_district",
                title: "Collector",
                isFromAPI: false,
                child: district
            },
            {
                type: "select-with-roleImg",
                key: "collector_details",
                title: "Collector",
                isFromAPI: true,
                role: "collector",
                child: []
            }
        ]
    },
    {
        type: "form",
        key: "speaker",
        title: "Speaker",
        viewKey: "speaker_details",
        //   role: "collector",
        viewRoleImg: "speaker",
        formData: [
            {
                type: "checkbox",
                title: "Gender",
                key: "speaker_gender",
                child: [
                    "Male",
                    "Female"
                ]
            },
            {
                type: "check",
                title: "Age",
                key: "speaker_age",
                child: ageRange
            },
            {
                type: "multiple-select",
                selects: [
                    {
                        type: "select",
                        isFromAPI: false,
                        key: "speaker_district",
                        title: "Speaker",
                        child: district
                    },
                    {
                        type: "select-with-roleImg",
                        key: "speaker_details",
                        title: "Speaker",
                        isFromAPI: true,
                        role: "speaker",
                        child: []
                    }
                ]
            }
        ]
    },
    {
        type: "date-ranger",
        key: "audioSubmissionPeriod",
        viewKey: "audioSubmissionPeriod",
        title: "Audio Submission Period",
    }

]

export const collectedAudioCheckingStatusFilterData: targetFilterDT[] = [
    {
        type: "select-with-checkbox",
        key: "script",
        title: "Script",
        //   child: ["STT", "TTS"],
        viewKey: "script",
        isFromAPI: true,
        child: []
    },
    {
        type: "multiple-select",
        key: "collector",
        title: "Collector",
        //   role: "collector",
        viewKey: "collector_details",
        viewRoleImg: "collector",
        selects: [
            {
                type: "select",
                key: "collector_district",
                title: "Collector",
                isFromAPI: false,
                child: district
            },
            {
                type: "select-with-roleImg",
                key: "collector_details",
                title: "Collector",
                isFromAPI: true,
                role: "collector",
                child: []
            }
        ]
    },
    {
        type: "form",
        key: "speaker",
        title: "Speaker",
        viewKey: "speaker_details",
        //   role: "collector",
        viewRoleImg: "speaker",
        formData: [
            {
                type: "checkbox",
                title: "Gender",
                key: "speaker_gender",
                child: [
                    "Male",
                    "Female"
                ]
            },
            {
                type: "check",
                title: "Age",
                key: "speaker_age",
                child: ageRange
            },
            {
                type: "multiple-select",
                selects: [
                    {
                        type: "select",
                        isFromAPI: false,
                        key: "speaker_district",
                        title: "Speaker",
                        child: district
                    },
                    {
                        type: "select-with-roleImg",
                        key: "speaker_details",
                        title: "Speaker",
                        isFromAPI: true,
                        role: "speaker",
                        child: []
                    }
                ]
            }
        ]
    },
    {
        type: "date-ranger",
        key: "audioUploadPeriod",
        viewKey: "audioUploadPeriod",
        title: "Audio Upload Period",
    },
    {
        type: "check",
        title: "Status",
        key: "status",
        viewKey: "status",
        child: [
            "Checking...",
            "Took a break",
        ]
    },

]

export const collectedAudioAllCheckingStatusFilterData: targetFilterDT[] = [
    {
        type: "select-with-checkbox",
        key: "script",
        title: "Script",
        //   child: ["STT", "TTS"],
        viewKey: "script",
        isFromAPI: true,
        child: []
    },
    {
        type: "multiple-select",
        key: "audioChecker",
        title: "Audio Checker",
        //   role: "collector",
        viewKey: "audioChecker_details",
        viewRoleImg: "audio checker",
        selects: [
            {
                type: "select",
                key: "audioChecker_district",
                title: "Audio Checker",
                isFromAPI: false,
                child: district
            },
            {
                type: "select-with-roleImg",
                key: "audioChecker_details",
                title: "Audio Checker",
                isFromAPI: true,
                role: "audio checker",
                child: []
            }
        ]
    },
    {
        type: "form",
        key: "speaker",
        title: "Speaker",
        viewKey: "speaker_details",
        //   role: "collector",
        viewRoleImg: "speaker",
        formData: [
            {
                type: "checkbox",
                title: "Gender",
                key: "speaker_gender",
                child: [
                    "Male",
                    "Female"
                ]
            },
            {
                type: "check",
                title: "Age",
                key: "speaker_age",
                child: ageRange
            },
            {
                type: "multiple-select",
                selects: [
                    {
                        type: "select",
                        isFromAPI: false,
                        key: "speaker_district",
                        title: "Speaker",
                        child: district
                    },
                    {
                        type: "select-with-roleImg",
                        key: "speaker_details",
                        title: "Speaker",
                        isFromAPI: true,
                        role: "speaker",
                        child: []
                    }
                ]
            }
        ]
    },
    {
        type: "multiple-select",
        key: "collector",
        title: "Collector",
        //   role: "collector",
        viewKey: "collector_details",
        viewRoleImg: "collector",
        selects: [
            {
                type: "select",
                key: "collector_district",
                title: "Collector",
                isFromAPI: false,
                child: district
            },
            {
                type: "select-with-roleImg",
                key: "collector_details",
                title: "Collector",
                isFromAPI: true,
                role: "collector",
                child: []
            }
        ]
    },
    {
        type: "date-ranger",
        key: "audioSubmissionPeriod",
        viewKey: "audioSubmissionPeriod",
        title: "Audio Submission Period",
    },
    {
        type: "check",
        title: "Status",
        key: "status",
        viewKey: "status",
        child: [
            "Checking...",
            "Took a break",
        ]
    },
]

export const collectedAudioAnnotationTypeFilterData: targetFilterDT[] = [
    {
        type: "select-with-checkbox",
        key: "script",
        title: "Script",
        //   child: ["STT", "TTS"],
        viewKey: "script",
        isFromAPI: true,
        child: []
    },
    {
        type: "form",
        key: "speaker",
        title: "Speaker",
        viewKey: "speaker_details",
        //   role: "collector",
        viewRoleImg: "speaker",
        formData: [
            {
                type: "checkbox",
                title: "Gender",
                key: "speaker_gender",
                child: [
                    "Male",
                    "Female"
                ]
            },
            {
                type: "check",
                title: "Age",
                key: "speaker_age",
                child: ageRange
            },
            {
                type: "multiple-select",
                selects: [
                    {
                        type: "select",
                        isFromAPI: false,
                        key: "speaker_district",
                        title: "Speaker",
                        child: district
                    },
                    {
                        type: "select-with-roleImg",
                        key: "speaker_details",
                        title: "Speaker",
                        isFromAPI: true,
                        role: "speaker",
                        child: []
                    }
                ]
            }
        ]
    },
]

export const collectedAudioAnnotationSentenceFilterData: targetFilterDT[] = [
    {
        type: "date-ranger",
        key: "dateRange",
        viewKey: "dateRange",
        title: "Date Range",
    },
    {
        type: "form",
        key: "speaker",
        title: "Speaker",
        viewKey: "speaker_details",
        //   role: "collector",
        viewRoleImg: "speaker",
        formData: [
            {
                type: "checkbox",
                title: "Gender",
                key: "speaker_gender",
                child: [
                    "Male",
                    "Female"
                ]
            },
            {
                type: "check",
                title: "Age",
                key: "speaker_age",
                child: ageRange
            },
            {
                type: "multiple-select",
                selects: [
                    {
                        type: "select",
                        isFromAPI: false,
                        key: "speaker_district",
                        title: "Speaker",
                        child: district
                    },
                    {
                        type: "select-with-roleImg",
                        key: "speaker_details",
                        title: "Speaker",
                        isFromAPI: true,
                        role: "speaker",
                        child: []
                    }
                ]
            }
        ]
    },
    {
        type: "multiple-select",
        key: "audioChecker",
        title: "Audio Checker",
        //   role: "collector",
        viewKey: "audioChecker_details",
        viewRoleImg: "audio checker",
        selects: [
            {
                type: "select",
                key: "audioChecker_district",
                title: "Audio Checker",
                isFromAPI: false,
                child: district
            },
            {
                type: "select-with-roleImg",
                key: "audioChecker_details",
                title: "Audio Checker",
                isFromAPI: true,
                role: "audio checker",
                child: []
            }
        ]
    },
]


export const collectedAudioAnnotationAnnotatedFilterData: targetFilterDT[] = [
    {
        type: "select-with-checkbox",
        key: "script",
        title: "Script",
        //   child: ["STT", "TTS"],
        viewKey: "script",
        isFromAPI: true,
        child: []
    },
    {
        type: "multiple-select",
        key: "annotator",
        title: "Annotator",
        //   role: "collector",
        viewKey: "annotator_details",
        viewRoleImg: "annotator",
        selects: [
            {
                type: "select",
                key: "annotator_district",
                title: "Annotator",
                isFromAPI: false,
                child: district
            },
            {
                type: "select-with-roleImg",
                key: "annotator_details",
                title: "Annotator",
                isFromAPI: true,
                role: "annotator",
                child: []
            }
        ]
    },
    {
        type: "multiple-select",
        key: "audioChecker",
        title: "Audio Checker",
        //   role: "collector",
        viewKey: "audioChecker_details",
        viewRoleImg: "audio checker",
        selects: [
            {
                type: "select",
                key: "audioChecker_district",
                title: "Audio Checker",
                isFromAPI: false,
                child: district
            },
            {
                type: "select-with-roleImg",
                key: "audioChecker_details",
                title: "Audio Checker",
                isFromAPI: true,
                role: "audio checker",
                child: []
            }
        ]
    },
    {
        type: "form",
        key: "speaker",
        title: "Speaker",
        viewKey: "speaker_details",
        //   role: "collector",
        viewRoleImg: "speaker",
        formData: [
            {
                type: "checkbox",
                title: "Gender",
                key: "speaker_gender",
                child: [
                    "Male",
                    "Female"
                ]
            },
            {
                type: "check",
                title: "Age",
                key: "speaker_age",
                child: ageRange
            },
            {
                type: "multiple-select",
                selects: [
                    {
                        type: "select",
                        isFromAPI: false,
                        key: "speaker_district",
                        title: "Speaker",
                        child: district
                    },
                    {
                        type: "select-with-roleImg",
                        key: "speaker_details",
                        title: "Speaker",
                        isFromAPI: true,
                        role: "speaker",
                        child: []
                    }
                ]
            }
        ]
    },
    {
        type: "multiple-select",
        key: "collector",
        title: "Collector",
        //   role: "collector",
        viewKey: "collector_details",
        viewRoleImg: "collector",
        selects: [
            {
                type: "select",
                key: "collector_district",
                title: "Collector",
                isFromAPI: false,
                child: district
            },
            {
                type: "select-with-roleImg",
                key: "collector_details",
                title: "Collector",
                isFromAPI: true,
                role: "collector",
                child: []
            }
        ]
    },
    {
        type: "date-ranger",
        key: "audioSubmissionPeriod",
        viewKey: "audioSubmissionPeriod",
        title: "Audio Submission Period",
    },
    {
        type: "check",
        title: "Status",
        key: "status",
        viewKey: "status",
        child: [
            "Checking...",
            "Took a break",
        ]
    },
]

export const collectedAudioValidationSentenceFilterData: targetFilterDT[] = [
    {
        type: "date-ranger",
        key: "dateRange",
        viewKey: "dateRange",
        title: "Date Range",
    },
    {
        type: "multiple-select",
        key: "annotator",
        title: "Annotator",
        //   role: "collector",
        viewKey: "annotator_details",
        viewRoleImg: "annotator",
        selects: [
            {
                type: "select",
                key: "annotator_district",
                title: "Annotator",
                isFromAPI: false,
                child: district
            },
            {
                type: "select-with-roleImg",
                key: "annotator_details",
                title: "Annotator",
                isFromAPI: true,
                role: "annotator",
                child: []
            }
        ]
    },
    {
        type: "form",
        key: "speaker",
        title: "Speaker",
        viewKey: "speaker_details",
        //   role: "collector",
        viewRoleImg: "speaker",
        formData: [
            {
                type: "checkbox",
                title: "Gender",
                key: "speaker_gender",
                child: [
                    "Male",
                    "Female"
                ]
            },
            {
                type: "check",
                title: "Age",
                key: "speaker_age",
                child: ageRange
            },
            {
                type: "multiple-select",
                selects: [
                    {
                        type: "select",
                        isFromAPI: false,
                        key: "speaker_district",
                        title: "Speaker",
                        child: district
                    },
                    {
                        type: "select-with-roleImg",
                        key: "speaker_details",
                        title: "Speaker",
                        isFromAPI: true,
                        role: "speaker",
                        child: []
                    }
                ]
            }
        ]
    },
    {
        type: "multiple-select",
        key: "audioChecker",
        title: "Audio Checker",
        //   role: "collector",
        viewKey: "audioChecker_details",
        viewRoleImg: "audio checker",
        selects: [
            {
                type: "select",
                key: "audioChecker_district",
                title: "Audio Checker",
                isFromAPI: false,
                child: district
            },
            {
                type: "select-with-roleImg",
                key: "audioChecker_details",
                title: "Audio Checker",
                isFromAPI: true,
                role: "audio checker",
                child: []
            }
        ]
    },
]

export const uploadAudioFilterData: targetFilterDT[] = [
    {
        type: "check",
        title: "Data Type",
        key: "dataType",
        viewKey: "dataType",
        child: [
            "STT",
            "TTS",
        ]
    },
    // {
    //     type: "multiple-select",
    //     key: "uploader",
    //     title: "Uploader",
    //     viewKey: "uploader_details",
    //     viewRoleImg: "collector",
    //     selects: [
    //         {
    //             type: "select",
    //             key: "uploader_district",
    //             title: "Uploader",
    //             isFromAPI: false,
    //             child: district
    //         },
    //         {
    //             type: "select-with-roleImg",
    //             key: "uploader_details",
    //             title: "Uploader",
    //             isFromAPI: true,
    //             role: "collector",
    //             child: []
    //         }
    //     ]
    // },
    {
        type: "date-ranger",
        key: "uploadPeriod",
        viewKey: "uploadPeriod",
        title: "Upload Period",
    },
    {
        type: "check",
        title: "Domain",
        key: "domain",
        viewKey: "domain",
        child: [
            "Leisure",
            "Educational/Informative",
            "Business",
            "Public/Institutional",
        ]
    },
]

export const uploadAudioCheckingFilterData: targetFilterDT[] = [
    {
        type: "check",
        title: "Status",
        key: "status",
        viewKey: "status",
        child: [
            "Checking...",
            "Took a break",
        ]
    },
]

export const uploadAudioAllCheckingFilterData: targetFilterDT[] = [
    {
        type: "multiple-select",
        key: "audioChecker",
        title: "Audio Checker",
        //   role: "collector",
        viewKey: "audioChecker_details",
        viewRoleImg: "audio checker",
        selects: [
            {
                type: "select",
                key: "audioChecker_district",
                title: "Audio Checker",
                isFromAPI: false,
                child: district
            },
            {
                type: "select-with-roleImg",
                key: "audioChecker_details",
                title: "Audio Checker",
                isFromAPI: true,
                role: "audio checker",
                child: []
            }
        ]
    },
    {
        type: "check",
        title: "Status",
        key: "status",
        viewKey: "status",
        child: [
            "Reject",
            "Approved",
            "Claimed",
            "Claime Approved",
            "CLaime Decline"
        ]
    },
    {
        type: "date-ranger",
        key: "audioSubmissionPeriod",
        viewKey: "audioSubmissionPeriod",
        title: "Audio Submission Period",
    }
]

export const uploadAudioAnnotationTypeFilterData: targetFilterDT[] = [
    {
        type: "form",
        key: "speaker",
        title: "Speaker",
        viewKey: "speaker",
        formData: [
            {
                type: "checkbox",
                title: "Gender",
                key: "speaker_gender",
                child: [
                    "Male",
                    "Female"
                ]
            },
            {
                type: "check",
                title: "Age",
                key: "speaker_age",
                child: ageRange
            },
            {
                type: "multiple-select",
                selects: [
                    {
                        type: "select",
                        isFromAPI: false,
                        key: "speaker_district",
                        title: "Speaker",
                        child: district
                    },
                ]
            }
        ]
    }
]

export const uploadAudioAnnotationAnnotatedFilterData: targetFilterDT[] = [
    {
        type: "multiple-select",
        key: "annotator",
        title: "Annotator",
        //   role: "collector",
        viewKey: "annotator_details",
        viewRoleImg: "annotator",
        selects: [
            {
                type: "select",
                key: "annotator_district",
                title: "Annotator",
                isFromAPI: false,
                child: district
            },
            {
                type: "select-with-roleImg",
                key: "annotator_details",
                title: "Annotator",
                isFromAPI: true,
                role: "annotator",
                child: []
            }
        ]
    },
    {
        type: "multiple-select",
        key: "audioChecker",
        title: "Audio Checker",
        //   role: "collector",
        viewKey: "audioChecker_details",
        viewRoleImg: "audio checker",
        selects: [
            {
                type: "select",
                key: "audioChecker_district",
                title: "Audio Checker",
                isFromAPI: false,
                child: district
            },
            {
                type: "select-with-roleImg",
                key: "audioChecker_details",
                title: "Audio Checker",
                isFromAPI: true,
                role: "audio checker",
                child: []
            }
        ]
    },
    {
        type: "form",
        key: "speaker",
        title: "Speaker",
        viewKey: "speaker_details",
        //   role: "collector",
        viewRoleImg: "speaker",
        formData: [
            {
                type: "checkbox",
                title: "Gender",
                key: "speaker_gender",
                child: [
                    "Male",
                    "Female"
                ]
            },
            {
                type: "check",
                title: "Age",
                key: "speaker_age",
                child: ageRange
            },
            {
                type: "multiple-select",
                selects: [
                    {
                        type: "select",
                        isFromAPI: false,
                        key: "speaker_district",
                        title: "Speaker",
                        child: district
                    },
                    {
                        type: "select-with-roleImg",
                        key: "speaker_details",
                        title: "Speaker",
                        isFromAPI: true,
                        role: "speaker",
                        child: []
                    }
                ]
            }
        ]
    },
    {
        type: "multiple-select",
        key: "collector",
        title: "Collector",
        //   role: "collector",
        viewKey: "collector_details",
        viewRoleImg: "collector",
        selects: [
            {
                type: "select",
                key: "collector_district",
                title: "Collector",
                isFromAPI: false,
                child: district
            },
            {
                type: "select-with-roleImg",
                key: "collector_details",
                title: "Collector",
                isFromAPI: true,
                role: "collector",
                child: []
            }
        ]
    },
    {
        type: "date-ranger",
        key: "audioSubmissionPeriod",
        viewKey: "audioSubmissionPeriod",
        title: "Audio Submission Period",
    },
    {
        type: "check",
        title: "Status",
        key: "status",
        viewKey: "status",
        child: [
            "Checking...",
            "Took a break",
        ]
    },
]

export const collectedAudioValidationValidatedFilterData: targetFilterDT[] = [
    {
        type: "select-with-checkbox",
        key: "script",
        title: "Script",
        //   child: ["STT", "TTS"],
        viewKey: "script",
        isFromAPI: true,
        child: []
    },
    {
        type: "multiple-select",
        key: "validator",
        title: "Validator",
        //   role: "collector",
        viewKey: "validator_details",
        viewRoleImg: "validator",
        selects: [
            {
                type: "select",
                key: "validator_district",
                title: "Validator",
                isFromAPI: false,
                child: district
            },
            {
                type: "select-with-roleImg",
                key: "validator_details",
                title: "Validator",
                isFromAPI: true,
                role: "validator",
                child: []
            }
        ]
    },
    {
        type: "multiple-select",
        key: "annotator",
        title: "Annotator",
        //   role: "collector",
        viewKey: "annotator_details",
        viewRoleImg: "annotator",
        selects: [
            {
                type: "select",
                key: "annotator_district",
                title: "Annotator",
                isFromAPI: false,
                child: district
            },
            {
                type: "select-with-roleImg",
                key: "annotator_details",
                title: "Annotator",
                isFromAPI: true,
                role: "annotator",
                child: []
            }
        ]
    },
    {
        type: "multiple-select",
        key: "audioChecker",
        title: "Audio Checker",
        //   role: "collector",
        viewKey: "audioChecker_details",
        viewRoleImg: "audio checker",
        selects: [
            {
                type: "select",
                key: "audioChecker_district",
                title: "Audio Checker",
                isFromAPI: false,
                child: district
            },
            {
                type: "select-with-roleImg",
                key: "audioChecker_details",
                title: "Audio Checker",
                isFromAPI: true,
                role: "audio checker",
                child: []
            }
        ]
    },
    {
        type: "form",
        key: "speaker",
        title: "Speaker",
        viewKey: "speaker_details",
        //   role: "collector",
        viewRoleImg: "speaker",
        formData: [
            {
                type: "checkbox",
                title: "Gender",
                key: "speaker_gender",
                child: [
                    "Male",
                    "Female"
                ]
            },
            {
                type: "check",
                title: "Age",
                key: "speaker_age",
                child: ageRange
            },
            {
                type: "multiple-select",
                selects: [
                    {
                        type: "select",
                        isFromAPI: false,
                        key: "speaker_district",
                        title: "Speaker",
                        child: district
                    },
                    {
                        type: "select-with-roleImg",
                        key: "speaker_details",
                        title: "Speaker",
                        isFromAPI: true,
                        role: "speaker",
                        child: []
                    }
                ]
            }
        ]
    },
    {
        type: "multiple-select",
        key: "collector",
        title: "Collector",
        //   role: "collector",
        viewKey: "collector_details",
        viewRoleImg: "collector",
        selects: [
            {
                type: "select",
                key: "collector_district",
                title: "Collector",
                isFromAPI: false,
                child: district
            },
            {
                type: "select-with-roleImg",
                key: "collector_details",
                title: "Collector",
                isFromAPI: true,
                role: "collector",
                child: []
            }
        ]
    },
    {
        type: "date-ranger",
        key: "audioSubmissionPeriod",
        viewKey: "audioSubmissionPeriod",
        title: "Audio Submission Period",
    },
    {
        type: "check",
        title: "Status",
        key: "status",
        viewKey: "status",
        child: [
            "Checking...",
            "Took a break",
        ]
    },
]




export const uploadAudioAnnotationValidatedFilterData: targetFilterDT[] = [
    {
        type: "multiple-select",
        key: "validator",
        title: "Validator",
        //   role: "collector",
        viewKey: "validator_details",
        viewRoleImg: "validator",
        selects: [
            {
                type: "select",
                key: "validator_district",
                title: "Validator",
                isFromAPI: false,
                child: district
            },
            {
                type: "select-with-roleImg",
                key: "validator_details",
                title: "Validator",
                isFromAPI: true,
                role: "validator",
                child: []
            }
        ]
    },
    {
        type: "multiple-select",
        key: "annotator",
        title: "Annotator",
        //   role: "collector",
        viewKey: "annotator_details",
        viewRoleImg: "annotator",
        selects: [
            {
                type: "select",
                key: "annotator_district",
                title: "Annotator",
                isFromAPI: false,
                child: district
            },
            {
                type: "select-with-roleImg",
                key: "annotator_details",
                title: "Annotator",
                isFromAPI: true,
                role: "annotator",
                child: []
            }
        ]
    },
    {
        type: "multiple-select",
        key: "audioChecker",
        title: "Audio Checker",
        //   role: "collector",
        viewKey: "audioChecker_details",
        viewRoleImg: "audio checker",
        selects: [
            {
                type: "select",
                key: "audioChecker_district",
                title: "Audio Checker",
                isFromAPI: false,
                child: district
            },
            {
                type: "select-with-roleImg",
                key: "audioChecker_details",
                title: "Audio Checker",
                isFromAPI: true,
                role: "audio checker",
                child: []
            }
        ]
    },
    {
        type: "form",
        key: "speaker",
        title: "Speaker",
        viewKey: "speaker_details",
        //   role: "collector",
        viewRoleImg: "speaker",
        formData: [
            {
                type: "checkbox",
                title: "Gender",
                key: "speaker_gender",
                child: [
                    "Male",
                    "Female"
                ]
            },
            {
                type: "check",
                title: "Age",
                key: "speaker_age",
                child: ageRange
            },
            {
                type: "multiple-select",
                selects: [
                    {
                        type: "select",
                        isFromAPI: false,
                        key: "speaker_district",
                        title: "Speaker",
                        child: district
                    },
                    {
                        type: "select-with-roleImg",
                        key: "speaker_details",
                        title: "Speaker",
                        isFromAPI: true,
                        role: "speaker",
                        child: []
                    }
                ]
            }
        ]
    },
    {
        type: "multiple-select",
        key: "collector",
        title: "Collector",
        //   role: "collector",
        viewKey: "collector_details",
        viewRoleImg: "collector",
        selects: [
            {
                type: "select",
                key: "collector_district",
                title: "Collector",
                isFromAPI: false,
                child: district
            },
            {
                type: "select-with-roleImg",
                key: "collector_details",
                title: "Collector",
                isFromAPI: true,
                role: "collector",
                child: []
            }
        ]
    },
    {
        type: "date-ranger",
        key: "audioSubmissionPeriod",
        viewKey: "audioSubmissionPeriod",
        title: "Audio Submission Period",
    },
    {
        type: "check",
        title: "Status",
        key: "status",
        viewKey: "status",
        child: [
            "Checking...",
            "Took a break",
        ]
    },
]



export const scriptFilterData = [
    {
        id: "17956e2a-19f1-416e-97a3-4c0d55869c7b",
        title: "সুখী রাজকুমার",
    },
    {
        id: "47956e2a-19f1-416e-97a3-4c0d55869c7b",
        title: "সুখী রাজকুমার",
    },
    {
        id: "37956e2a-19f1-416e-97a3-4c0d55869c7b",
        title: "সুখী রাজকুমার",
    },
    {
        id: "27956e2a-19f1-416e-97a3-4c0d55869c7b",
        title: "সুখী রাজকুমার",
    },
    {
        id: "57956e2a-19f1-416e-97a3-4c0d55869c7b",
        title: "সুখী রাজকুমার",
    }

]

export const collectedAudioAnnotatorList = [
    {
        "id": "maksudalam@gmail.com",
        "name": "Maksud Alam",
        "role": "collector",
        "contact": "01738463449",
        "email": "maksudalam@gmail.com",
        "address": "Dhaka",
        "gender": "male"
    },
    {
        "id": "01738463442",
        "name": "Steve Smith",
        "role": "collector",
        "contact": "01738463442",
        "email": "stevesmith@cric.au",
        "address": "Dhaka",
        "gender": "male"
    },
    {
        "id": "mitchstarc@revesoft.au",
        "name": "Mitchell Starc",
        "role": "collector",
        "contact": "01738463442",
        "email": "mitchstarc@revesoft.au",
        "address": "Dhaka",
        "gender": "male"
    },
]

export const collectedAudioValidatorList = [
    {
        "id": "maksudalam@gmail.com",
        "name": "Maksud Alam",
        "role": "collector",
        "contact": "01738463449",
        "email": "maksudalam@gmail.com",
        "address": "Dhaka",
        "gender": "male"
    },
    {
        "id": "01738463442",
        "name": "Steve Smith",
        "role": "collector",
        "contact": "01738463442",
        "email": "stevesmith@cric.au",
        "address": "Dhaka",
        "gender": "male"
    },
    {
        "id": "mitchstarc@revesoft.au",
        "name": "Mitchell Starc",
        "role": "collector",
        "contact": "01738463442",
        "email": "mitchstarc@revesoft.au",
        "address": "Dhaka",
        "gender": "male"
    },
]


export const collectedAudioCollectorList = [
    {
        "id": "maksudalam@gmail.com",
        "name": "Maksud Alam",
        "role": "collector",
        "contact": "01738463449",
        "email": "maksudalam@gmail.com",
        "address": "Dhaka",
        "gender": "male"
    },
    {
        "id": "01738463442",
        "name": "Steve Smith",
        "role": "collector",
        "contact": "01738463442",
        "email": "stevesmith@cric.au",
        "address": "Dhaka",
        "gender": "male"
    },
    {
        "id": "mitchstarc@revesoft.au",
        "name": "Mitchell Starc",
        "role": "collector",
        "contact": "01738463442",
        "email": "mitchstarc@revesoft.au",
        "address": "Dhaka",
        "gender": "male"
    },
]

export const collectedAudioSpeakersList = [
    {
        "id": "maksudalam@gmail.com",
        "name": "Maksud Alam",
        "role": "speaker",
        "contact": "01738463449",
        "email": "maksudalam@gmail.com",
        "address": "Dhaka",
        "gender": "male"
    },
    {
        "id": "01738463442",
        "name": "Kamola Banu",
        "role": "speaker",
        "contact": "01738463442",
        "email": "stevesmith@cric.au",
        "address": "Dhaka",
        "gender": "female"
    },
    {
        "id": "mitchstarc@revesoft.au",
        "name": "Mitchell Starc",
        "role": "speaker",
        "contact": "01738463442",
        "email": "mitchstarc@revesoft.au",
        "address": "Dhaka",
        "gender": "male"
    },
]

export const collectedAudioCheckerList = [
    {
        "id": "maksudalam@gmail.com",
        "name": "Maksud Alam",
        "role": "speaker",
        "contact": "01738463449",
        "email": "maksudalam@gmail.com",
        "address": "Dhaka",
        "gender": "male"
    },
    {
        "id": "01738463442",
        "name": "Kamola Banu",
        "role": "speaker",
        "contact": "01738463442",
        "email": "stevesmith@cric.au",
        "address": "Dhaka",
        "gender": "female"
    },
    {
        "id": "mitchstarc@revesoft.au",
        "name": "Mitchell Starc",
        "role": "speaker",
        "contact": "01738463442",
        "email": "mitchstarc@revesoft.au",
        "address": "Dhaka",
        "gender": "male"
    },
]

export const statusType = [
    'STT',
    'TTS'
]

export const audionManDistributionSource = [
    'Read',
    'Spontaneous',
    'Lecture',
    'Miscellaneous',
    'Noisy'
]

export const audioManAgeRange = [
    '7-14',
    '15-24',
    '25-34',
    '35-44',
    '45-49',
    '60-65'
]
export const statusColorsData = [
    {
        "name": STATUS_CANCELLED,
        "bgColor": "bg-red-10",
        "bulletColor": "bg-secondary-red-50",
        "textColor": "text-red-60"
    },
    {
        "name": STATUS_DECLINED,
        "bgColor": "bg-red-10",
        "bulletColor": "bg-secondary-red-50",
        "textColor": "text-red-60"
    },
    {
        "name": STATUS_APPROVED,
        "bgColor": "bg-green-10",
        "bulletColor": "bg-secondary-green-50",
        "textColor": "text-green-60"
    },
    {
        "name": STATUS_CLAIM_APPROVED,
        "bgColor": "bg-green-10",
        "bulletColor": "bg-secondary-green-50",
        "textColor": "text-green-60"
    },
    {
        "name": STATUS_REASSIGNED,
        "bgColor": "bg-blue-10",
        "bulletColor": "bg-secondary-blue-50",
        "textColor": "text-[#0C4A9C]"
    },
    {
        "name": STATUS_CLAIMED,
        "bgColor": "bg-purple-10",
        "bulletColor": "bg-secondary-purple-50",
        "textColor": "text-purple-60"
    },
    {
        "name": STATUS_ACTIVE,
        "bgColor": "bg-green-10",
        "bulletColor": "bg-secondary-green-50",
        "textColor": "text-green-60"
    },
    {
        "name": STATUS_BLOCKED,
        "bgColor": "bg-red-10",
        "bulletColor": "bg-secondary-red-50",
        "textColor": "text-red-60"
    },
    {
        "name": STATUS_REJECTED,
        "bgColor": "bg-red-10",
        "bulletColor": "bg-secondary-red-50",
        "textColor": "text-red-60"
    },
    {
        "name": STATUS_VALIDATED,
        "bgColor": "bg-green-10",
        "bulletColor": "bg-secondary-green-50",
        "textColor": "text-green-60"
    },
    {
        "name": STATUS_CHECKED,
        "bgColor": "bg-orange-A10",
        "bulletColor": "bg-orange-60",
        "textColor": "text-secondary-orange-50"
    },
    {
        "name": STATUS_ANNOTATED,
        "bgColor": "bg-[#F3F4F6]",
        "bulletColor": "bg-[#4B5563]",
        "textColor": "text-[#4B5563]"
    },
    {
        "name": STATUS_UPOLOADED,
        "bgColor": "bg-blue-10",
        "bulletColor": "bg-secondary-blue-50",
        "textColor": "text-secondary-blue-50"
    },
]

