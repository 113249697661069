import { lazy } from "react";

export const Billing = lazy(() => import('../pages/Billing'));
export const PaymentHistory = lazy(() => import('../pages/Billing/PaymentHistory'));
export const Dashboard = lazy(() => import('../pages/Dashboard'));
export const Script = lazy(() => import('../pages/Script'));
export const AddScript = lazy(() => import('../components/containers/script/AddScript'));
export const EditScript = lazy(() => import('../components/containers/script/EditScript'));
export const UserManagement = lazy(() => import('../pages/UserManagement'));
export const Activity = lazy(() => import('../pages/UserManagement/Activity'));
export const Speech = lazy(() => import('../pages/UserManagement/Speech'));
export const UserForm = lazy(() => import('../components/containers/userManagement/UserForm'));
export const EditUser = lazy(() => import('../components/containers/userManagement/EditUser'));
export const Assign = lazy(() => import('../pages/Assign'));
export const AssignContainer = lazy(() => import('../components/containers/AssignContainer'));
export const AllTarget = lazy(() => import('../components/containers/AssignContainer/AllTarget'));
export const Draft = lazy(() => import('../pages/Assign/Draft'));
export const CreateTarget = lazy(() => import('../pages/Assign/CreateTarget')); 
export const RecreateTarget = lazy(() => import('../pages/Assign/RecreateTarget'));
export const EditSpeeches = lazy(() => import('../components/containers/AssignContainer/AllTarget/EditSpeeches'));
export const AudioManagement = lazy(() => import('../pages/AudioManagement'));
export const CollectedAudio = lazy(() => import('../pages/AudioManagement/CollectedAudio'));
export const CheckingStatus = lazy(() => import('../pages/AudioManagement/CollectedAudio/Checking/CheckingStatus'));
export const AllCheckedAudios = lazy(() => import('../pages/AudioManagement/CollectedAudio/Checking/AllCheckedAudios'));
export const Annotation = lazy(() => import('../pages/AudioManagement/CollectedAudio/Annotation'));
export const SentenceLevel = lazy(() => import('../pages/AudioManagement/CollectedAudio/Annotation/SentenceLevel'));
export const WordLevel = lazy(() => import('../pages/AudioManagement/CollectedAudio/Annotation/WordLevel'));
export const PhonemeLevel = lazy(() => import('../pages/AudioManagement/CollectedAudio/Annotation/PhonemeLevel'));
export const AnnotatedFiles = lazy(() => import('../pages/AudioManagement/CollectedAudio/Annotation/AnnotatedFiles'));
export const Validation = lazy(() => import('../pages/AudioManagement/CollectedAudio/Validation'));
export const SentenceLevelValidation = lazy(() => import('../pages/AudioManagement/CollectedAudio/Validation/SentenceLevelValidation'));
export const WordLevelValidation = lazy(() => import('../pages/AudioManagement/CollectedAudio/Validation/WordLevelValidation'));
export const PhonemeLevelValidation = lazy(() => import('../pages/AudioManagement/CollectedAudio/Validation/PhonemeLevelValidation'));
export const ValidatedFiles = lazy(() => import('../pages/AudioManagement/CollectedAudio/Validation/ValidatedFiles'));
export const UploadAudio = lazy(() => import('../pages/AudioManagement/UploadAudio'));
export const AddSpeech = lazy(() => import('../components/containers/AudioManagement/UploadAuidioVideo/AddSpeech'));
export const CheckingUploadAudio = lazy(() => import('../pages/AudioManagement/UploadAudio/Checking'));
export const CheckingStatusUpload = lazy(() => import('../pages/AudioManagement/UploadAudio/Checking/CheckingStatusUpload'));
export const AllCheckedAudiosUpload = lazy(() => import('../pages/AudioManagement/UploadAudio/Checking/AllCheckedSpeechUpload'));
export const AnnotationUpload = lazy(() => import('../pages/AudioManagement/UploadAudio/Annotation'));
export const SentenceLevelUpload = lazy(() => import('../pages/AudioManagement/UploadAudio/Annotation/SentenceLevelUpload'));
export const WordLevelUpload = lazy(() => import('../pages/AudioManagement/UploadAudio/Annotation/WordLevelUpload'));
export const PhonemeLevelUpload = lazy(() => import('../pages/AudioManagement/UploadAudio/Annotation/PhonemeLevelUpload'));
export const AnnotatedFilesUpload = lazy(() => import('../pages/AudioManagement/UploadAudio/Annotation/AnnotatedFilesUpload'));
export const ValidationUpload = lazy(() => import('../pages/AudioManagement/UploadAudio/Validation'));
export const SentenceLevelUploadVal = lazy(() => import('../pages/AudioManagement/UploadAudio/Validation/SentenceLevelUploadVal'));
export const WordLevelUploadVal = lazy(() => import('../pages/AudioManagement/UploadAudio/Validation/WordLevelUploadVal'));
export const PhonemeLevelUploadVal = lazy(() => import('../pages/AudioManagement/UploadAudio/Validation/PhonemeLevelUploadVal'));
export const ValidatedFilesUpload = lazy(() => import('../pages/AudioManagement/UploadAudio/Validation/ValidatedFilesUpload'));
export const Organizer = lazy(() => import('../pages/Organizer'));
export const Role = lazy(() => import('../pages/Organizer/Role'));
export const Tag = lazy(() => import('../pages/Organizer/Tag'));
export const Device = lazy(() => import('../pages/Organizer/Device'));