export const ROOT_PATH = "/";
export const LOGIN_PATH = "/login";
export const REGISTER_PATH = "/register";
export const BILLING_PATH = "/billing";
export const DASHBOARD_PATH = "/dashboard";
export const AUDIO_PATH = "/audio-management";
export const USER_PATH = "/user-management";
export const ORGANIZER_PATH = "/organizer";
export const SETTINGS_PATH = "/settings";
export const BILLING_PAYMENT_HISTORY_PATH = "/billing/payment-history";
// AssignContainer
export const ASSIGN_PATH = "/assign";
export const ALL_TARGET_PTAH = 'all-target';
export const DRAFT_PATH  = '/assign/draft';
// export const CREATE_TARGET_PATH = 'create-target';
export const CREATE_TARGET_PATH = `${ASSIGN_PATH}/${ALL_TARGET_PTAH}/create-target`;
export const RECREATE_TARGET_PATH = `${ASSIGN_PATH}/${ALL_TARGET_PTAH}/recreate`;
export const EDIT_SPEECHES_PATH = `${ASSIGN_PATH}/${ALL_TARGET_PTAH}/edit-speeches`;




// Script
export const SCRIPT_PATH = "/script";
export const CREATE_SCRIPT = "/script/create-script";
export const EDIT_SCRIPT_PATH = "/script/edit-script";

// User Management
export const CREATE_USER_PATH = "/user-management/create-user";
export const ACTIVE_USER_PATH = "/user-management/activity";
export const USER_MANAGEMENT_SPEECHES_PATH = "speeches";
export const EDIT_USER_PATH = "/script/edit-user";

//Audio Management
export const TEST = "/test";
export const AUDIO_MANAGEMENT = '/audio-management'
export const COLLECTING_AUDIO = `collection-audio`;
export const CHECKING = "checking";
export const ANNOTAION = "annotation";
export const ANNOTAION_TYPE = "annotation-type";
export const VALIDATION = "validation";
export const CHECKING_STATUS = `checking-status`;
export const ALL_CHECKED_AUDIOS = "all-checked-audios";
export const SENETENCE_LEVEL = "sentence-level";
export const WORD_LEVEL = "word-level";
export const PHONEME_LEVEL = "phoneme-level";
export const ANNOTATED_FILES = "annotated-files";
export const VALIDATED_FILES = "validated-files";
export const UPLOAD_AUDION_VIDEO_PATH = `upload-audio-video`;
export const ADD_SPEECH_PATH = `add-speech`;

//Ortganizer
export const ROLE = "role";
export const TAG = "tag";
export const DEVICE = "device";


// export const AUDIO_FILE_UPLOADED = 'Uploaded';
// export const AUDIO_FILE_FAILED = 'Upload Failed';
